import React, { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';

const Navbar = ({page}) => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [title, setTitle] = useState('')
  useEffect(() => {
    const getData = async () => {
      if (page === 'dashboard'){
        setTitle('Dashboard')
      } else if (page === 'website') {
        // console.log('id', id)
        if(id === 'home'){
          setTitle('Website / Home Page')
        } else if (id === 'about') {
          setTitle('Website / About Page')
        } else if (id === 'hellen') {
          setTitle("Website / Hellen's Story")
        } else if (id === 'campaigns') {
          setTitle('Website / Campaigns')
        } else if (id === 'programs') {
          setTitle('Website / Program Areas')
        }
      } else if (page === 'mobilefeed') {
        // console.log('id', id)
        if (id === 'create') {
          setTitle('Mobile App Feed / Create Post')
        } else if (id === 'types') {
          setTitle('Mobile App Feed / Post Types')
        }
      } else if (page === 'mobilecases') {
        // console.log('id', id)
        if (id === 'reports') {
          setTitle('Mobile App Cases / Reports')
        } else if (id === 'types') {
          setTitle('Mobile App Cases / Case Types')
        }
      } else if (page === 'mobilecommunities') {
        setTitle('Mobile App Communities')
      } else if (page === 'accounts') {
        // console.log('id', id)
        if (id === 'approved') {
          setTitle('Admin Accounts / Approved')
        } else if (id === 'pending') {
          setTitle('Admin Accounts / Pending')
        }
      } else if (page === 'profile') {
        setTitle('Account / Profile')
      }
    }
    getData();
  }, [page,id])
  

  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-transparent  bg-primary  navbar-absolute">
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button type="button" className="navbar-toggler">
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <a className="navbar-brand" href="#pablo">{title}</a>
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navigation">
          
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="now-ui-icons users_single-02"></i>
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <Link to="/profile" className="dropdown-item">Profile</Link>
                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">Log Out</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
      <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Log Out ?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Confirm Logout
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={()=> {
                localStorage.clear()
                navigate("/signin");
              }} data-dismiss="modal" className="btn btn-primary">Yes, proceed</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import authorizeGetRequest from '../api/authorizeGetRequest';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const mapCenter = {
  lat: 1.373,
  lng: 32.290
};
const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState(true)
  const [chartData, setchartData] = useState({
    labels: ['Victim', 'Reporter', 'Perpetrator', 'Care taker'],
    datasets: [
      {
        label: 'People',
        backgroundColor: '#832a84',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81]
      }
    ]
  })
  const [pieChartData, setPieChartData] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [nopieData, setNoPieData] = useState(true)
  const [requestedServices, setRequestedServices] = useState()
  const [noServiceData, setNoServiceData] = useState(true)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GoogleMapsApiKey
  })
  const [cordsData, setCordsData] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await authorizeGetRequest('mobileApp/analytics')
        .then((response) => {
          if (response.success === true) {
            // console.log('cases analytics', response.cases);
            if (response.cases !== 0) {
              let cords = []
              response.cases.forEach(element => {
                let s1 = element.area_incident.split('####')
                if (s1[1]){
                  s1 = s1[1].split(',')
                  // cords.push([s1[0],s1[1]])
                  cords.push({
                    lat: Number(s1[0]),
                    lng: Number(s1[1])
                  })
                }
                
              });
              setCordsData(cords)
              console.log('split 1', cords)
            } else {
              // setData([])
            }
          } else {
            // setData([])
            // console.log('adminFeed failed', response);
          }
        })
        .catch((err) => {
          setData([])
          console.log('adminFeed error', err);
        });
      const a = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': 'VFpZ2rNwQbFz9cS-fiJm1vX_HBrJHq_NyBuAeWgnhBU',
        },
      });
      let baseURL = `https://admin.rapehurtsfoundation.org/api/reportedcases/total`;
      await a
        .get(baseURL)
        .then((response) => {
          // handle success
          if (response.data.status === 200) {
            setData(response.data)
            let barchartLabels = [], barChartData = [];
            response.data.data.total_bio_types.forEach(element => {
              barchartLabels.push(element.biotype_id__name)
              barChartData.push(element.total)
            });
            setchartData({
              labels: barchartLabels,
              datasets: [
                {
                  label: 'People',
                  backgroundColor: '#832a84',
                  borderColor: 'rgba(0,0,0,1)',
                  borderWidth: 2,
                  data: barChartData
                }
              ]
            })
            setNoData(false)
          } else {
            setNoData(true)
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            console.log('error1', error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error2', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('error3', error.message);
          }
          setNoData(true)
        });
      baseURL = `https://admin.rapehurtsfoundation.org/api/reportedcases/regional`;
      await a
        .get(baseURL)
        .then((response) => {
          // handle success
          if (response.data.status === 200) {
            // setData(response.data)
            let area_incident = [], pietotal = [], tt6;
            response.data.data.forEach(element => {
              area_incident.push(element.area_incident)
              pietotal.push(element.total)
            });
            // sum all elements of array
            const sum = pietotal.reduce((partialSum, a) => partialSum + a, 0);
            tt6 = pietotal[0] + pietotal[1] + pietotal[2] + pietotal[3] + pietotal[4]
            tt6 = sum - tt6
            pietotal = [pietotal[0], pietotal[1], pietotal[2], pietotal[3], pietotal[4], tt6]
            area_incident = [area_incident[0], area_incident[1], area_incident[2], area_incident[3], area_incident[4], 'Others']
            setPieChartData({
              labels: area_incident,
              datasets: [
                {
                  label: 'Cases',
                  data: pietotal,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(153, 12, 255, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(235, 139, 214, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 12, 255, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(235, 139, 214, 1)',
                  ],
                  borderWidth: 1,
                },
              ],
            })
            setNoPieData(false)
          } else {
            setNoPieData(true)
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            console.log('error1', error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error2', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('error3', error.message);
          }
          setNoPieData(true)
        });
      baseURL = `https://admin.rapehurtsfoundation.org/api/services/most`;
      await a
        .get(baseURL)
        .then((response) => {
          // handle success
          // console.log('setRequestedServices', response.data)
          if (response.data.status === 200) {
            setRequestedServices(response.data)
            setNoServiceData(false)
          } else {
            setNoServiceData(true)
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            console.log('error1', error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error2', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('error3', error.message);
          }
          setNoServiceData(true)
        });
      setLoading(false);
    }
    getData()
  }, [])

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {loading ? (
              <>
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card-header">
                  <h5 className="title">Case Data Analytics</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-4 col-md-6 mb-4">
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Open Cases</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">{data.data.total_open_cases}</div>
                            </div>
                          </div>
                        </div >
                      </div >
                    </div>
                    <div className="col-xl-4 col-md-6 mb-4">
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Closed Cases</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">{data.data.total_closed_cases}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6 mb-4">
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Delayed Cases</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">{data.data.total_open_cases_delayed}</div>
                            </div>
                          </div>
                        </div >
                      </div >
                    </div>
                  </div>
                  <div className="mb-5" style={{ maxHeight: 400 }}>
                    <h5 className="title">Total Number of People Per Bio Data Type</h5>
                    {!noData && (
                      <Bar
                        data={chartData}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'top',
                            },
                            title: {
                              display: false,
                            },
                          },
                        }}
                      />
                    )}
                  </div>
                  <div className="mb-5 mt-5">
                    <h5 className="title">Total Reported cases per region</h5>
                    <div className="d-flex align-items-center justify-content-center w-100" style={{ maxHeight: 400 }}>
                      {!noData && (
                        <Pie data={pieChartData} options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'right',
                            },
                            title: {
                              display: false,
                            },
                          },
                        }} />
                      )}
                    </div>
                  </div>
                  {isLoaded ? (
                    <>
                        <h5 className="title">Map showing density of open cases in uganda</h5>
                      <GoogleMap
                        zoom={7}
                        mapContainerStyle={{ height: '600px' }}
                        center={mapCenter}
                      // onLoad={onLoad}
                      // onUnmount={onUnmount}
                      >
                        { /* Child components, such as markers, info windows, etc. */}
                        <>
                          {cordsData.length >= 1 && (
                            <>
                              {cordsData.map((item, index) => (
                                <Marker key={index} position={item} />
                              ))}
                            </>
                          )}
                            {/* <Marker position={mapCenter} /> */}
                        </>
                      </GoogleMap>
                    </>
                  ) : (
                    <>
                      <div className="loader m-5 text-center">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                      <p>{loadError}</p>
                    </>
                  )}
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard

import { useState } from 'react'

const ImgNContent = ({ data, handleBannerDataAdd, handleBannerDataDelete, handleBannerImgChange, onChangeCaption, onChangeBannerTitle }) => {
  const [reload, setReload] = useState(0)

  return (
    <>
      {data.length >= 1 && (
        <>
          {data.map((item, index) => {
            const ind = index + 1
            return (
              <div key={index} className='col-sm-12'>
                <div className='form-group'>
                  <label htmlFor='subject'>{`Banner ${ind} (dimensions - 1920 * 1080)`}</label>
                  <div className='col-sm-12 p-0 mb-2'>
                    <input
                      type="file"
                      name='img'
                      accept="image/*"
                      multiple={false}
                      onChange={(e) => {
                        handleBannerImgChange(index, e.target.files)
                        setReload(reload + 1)
                      }}
                       />
                  </div>
                  <input
                    name='title'
                    value={item.title}
                    onChange={(e) => {
                      onChangeBannerTitle(index, e.target.value)
                      setReload(reload + 1)
                    }}
                    placeholder='Title'
                    className='form-control form-control-lg mb-2'
                    required={true}
                  />
                  <textarea
                    name='content'
                    placeholder='Caption *'
                    value={item.caption}
                    onChange={(e) => {
                      onChangeCaption(index, e.target.value)
                      setReload(reload + 1)
                    }}
                    className='form-control form-control-lg'></textarea>
                  {/* check if element is last one & more than 1 then show else check if element is 1 then show else none */}
                  {data.length === ind && data.length >= 1 && data.length <= 2 && (
                    <button type="button" onClick={() => handleBannerDataAdd(ind)} className="btn btn-primary btn-icon btn-round">
                      <i className="fas fa-plus"></i>
                    </button>
                  )}

                  {data.length > 1 && (
                    <button type="button" onClick={() => {
                      handleBannerDataDelete(index)
                      setReload(reload + 1)
                    }} className="btn btn-danger btn-icon btn-round ml-3">
                      <i className="fas fa-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            )
          }
          )}
        </>
      )}
    </>
  )
}

export default ImgNContent

import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import authorizePatchRequest from '../api/authorizePatchRequest'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizePostRequest from '../api/authorizePostRequest';

const CasesModal = ({ data, caseId, updateStatusItem }) => {
  const [loading, setLoading] = useState(true)
  const [msg, setmsg] = useState('')
  const [isActive, setisActive] = useState(true)
  const [notes, setNotes] = useState('')
  const [caseStatus, setCaseStatus] = useState('')

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      
      if (data) {
        // console.log('data', data)
        if (data === 'note') {
          setmsg('Add notes to case')
        } else {
          setCaseStatus(data.status)
          setisActive(true)
          setmsg('')
        }
        setLoading(false)
      }
    }
    getData()
  }, [data])

  const onSubmitNotes = async (e) => {
    e.preventDefault();
    setLoading(true)
    const dt = {
      note: notes,
      caseId: caseId
    }
    await authorizePostRequest('mobileApp/casenotes', dt)
      .then((response) => {
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        updateStatusItem(response.notes)
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false)
  }

  return (
    <div className="modal fade" id="caseModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{data === 'note' ? msg : 'Change Case Status'}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {loading ? (
            <>
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <form method='post' className='p-3' onSubmit={onSubmitNotes}>
                <div className="modal-body">
                  {data === 'note' ? (
                    <div className='col-lg-12 col-sm-11'>
                      <div className='form-group'>
                        <label htmlFor='subject'>Notes</label>
                        <textarea
                          // rows="10"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          required
                          className='form-control form-control-lg'></textarea>
                      </div>
                    </div>

                  ) : (
                    <>
                      {msg ? <p>{`${msg}`}</p> : (
                        <div className='col-lg-12 col-sm-11'>
                          <div className='form-group'>
                            <label htmlFor='inputGroupSelect01'>Case Status</label>
                            <select value={caseStatus} onChange={(e) => setCaseStatus(e.target.value)} className="form-control form-control-lg" id="inputGroupSelect01" required>
                              <option>Choose...</option>
                              <option value='0'>Open case</option>
                              <option value='1'>Close case</option>
                              <option value='2'>In review</option>
                              <option value='3'>Delayed case</option>
                            </select>
                          </div>
                        </div>
                      )}

                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{isActive ? 'Cancel' : 'Close'}</button>
                  {data === 'note' ? (
                    <button type="submit" className="btn btn-secondary">Submit</button>
                  ) : (
                    <>
                      {isActive && (
                        <button type="button"
                          onClick={async () => {
                            setLoading(true)
                            let dt = { status: caseStatus }
                            await authorizePatchRequest(`mobileApp/caseStatus/${data.id}`, dt)
                              .then((response) => {
                                if (response.success === true) {
                                  console.log('cases update success', response.cases);
                                  authorizeGetRequest(`mobileApp/fcm/${data.userId}`)
                                    .then((response1) => {
                                      // console.log('fcm got', response1.fcm)
                                      const dt1 = {
                                        title: 'Case update',
                                        message: `Your case status has changed to ${dt.status === '1' ? 'CLOSED' : dt.status === '2' ? 'IN REVIEW' : dt.status === '3' ? 'DELAYED (Contact RHF admin)' : 'OPEN'}`,
                                      }
                                      if (response1.fcm !== 0) {
                                        const a = axios.create({
                                          headers: {
                                            'Content-Type': 'application/json',
                                            'X-Api-Key': process.env.REACT_APP_XApiKey,
                                          },
                                        });
                                        let baseURL = `https://firebase.munoclub.com/api/v1/single/${response1.fcm}`;
                                        a.post(baseURL, dt1)
                                          .then((response2) => {
                                            // console.log('notif sent', response2)
                                          })
                                          .catch((error) => {
                                            if (error.response) {
                                              // The request was made and the server responded with a status code
                                              console.log('error1', error.response.data);
                                            } else if (error.request) {
                                              // The request was made but no response was received
                                              console.log('error2', error.request);
                                            } else {
                                              // Something happened in setting up the request that triggered an Error
                                              console.log('error3', error.message);
                                            }
                                          });
                                      }
                                    })
                                    .catch((err) => {

                                    })
                                  updateStatusItem(response.cases[0])
                                  setmsg(response.message)
                                  setisActive(false)
                                }
                              })
                              .catch((err) => {
                                setmsg(err.message || 'something went wrong')
                                // setData([])
                                console.log('filter error', err);
                                setLoading(false)
                                return
                              });
                            setLoading(false)
                          }} className="btn btn-primary">Yes, proceed</button>
                      )}
                    </>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CasesModal

import React, { useState } from 'react'

const TitleNParagraph = ({ paragraphs, onChangeParagraphTitle, handleParagraphAdd, handleParagraphDelete, onChangeParagraph, handleParagraphTitleDel }) => {
  const [reload, setReload] = useState(0)
  
  return (
    <>
      {paragraphs.length >= 1 && (
        <>
          {paragraphs.map((item, index) => {
            const ind = index + 1
            return (
              <div key={index} className='col-lg-12 col-sm-11'>
                <div className='form-group'>
                  <label htmlFor='subject'>{`Paragraph ${ind}`}</label>
                  {item.titleVisible && (
                    <span className='d-flex flex-row'>
                      <input
                        name='title'
                        value={item.title}
                        onChange={(e) => {
                          onChangeParagraphTitle(index, e.target.value)
                          setReload(reload + 1)
                        }}
                        placeholder='Paragraph title'
                        className='form-control form-control-lg'
                      />
                      <button type="button" onClick={() => {
                        handleParagraphTitleDel(index)
                        setReload(reload + 1)
                      }} className="btn btn-danger btn-icon btn-round ml-2">
                        <i className="fas fa-trash"></i>
                      </button>
                    </span>
                  )}
                  <textarea
                    name='content'
                    placeholder='Paragraph content *'
                    value={item.paragraph}
                    onChange={(e) => {
                      onChangeParagraph(index, e.target.value)
                      setReload(reload + 1)
                    }}
                    className='form-control form-control-lg'></textarea>
                  {/* check if element is last one & more than 1 then show else check if element is 1 then show else none */}
                  {paragraphs.length === ind && paragraphs.length >= 1 && (
                    <button type="button" onClick={() => handleParagraphAdd(ind)} className="btn btn-primary btn-icon btn-round">
                      <i className="fas fa-plus"></i>
                    </button>
                  )}

                  {paragraphs.length > 1 && (
                    <button type="button" onClick={() => {
                      handleParagraphDelete(index)
                      setReload(reload + 1)
                    }} className="btn btn-danger btn-icon btn-round ml-3">
                      <i className="fas fa-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            )
          }
          )}
        </>
      )}
    </>
  )
}

export default TitleNParagraph

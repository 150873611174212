import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import authorizeGetRequest from '../api/authorizeGetRequest';
import authorizeDeleteRequest from '../api/authorizeDeleteRequest';
import authorizePostMultipart from '../api/authorizePostMultipart';
import authorizePatchMultipart from '../api/authorizePatchMultipart';
import authorizePostRequest from '../api/authorizePostRequest';
import authorizePatchRequest from "../api/authorizePatchRequest";

const MobileAppCommunities = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false)
  const [editFeed, setEditFeed] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState("")
  const [img, setImg] = useState('')
  const [status, setStatus] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [editFeedId, setEditFeedId] = useState()
  const [imgPreview, setImgPreview] = useState('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const a = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': 'VFpZ2rNwQbFz9cS-fiJm1vX_HBrJHq_NyBuAeWgnhBU',
        },
      });
      let baseURL = `https://admin.rapehurtsfoundation.org/api/communities`;
      await a
        .get(baseURL)
        .then((response) => {
          // handle success
          // console.log('response', response.data)
          if (response.data.status === 200) {
            setData(response.data)
          } else {
            setData([])
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            console.log('error1', error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.log('error2', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('error3', error.message);
          }
          setData([])
        });
      setLoading(false);
    }
    getData();
  }, []);

  const handleImgChange = e => {
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg(e.target.files[0]);
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
  }

  const onSubmitFeed = async (e) => {
    e.preventDefault()
    setLoading(true);
    const a = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'VFpZ2rNwQbFz9cS-fiJm1vX_HBrJHq_NyBuAeWgnhBU',
      },
    });
    let baseURL = `https://admin.rapehurtsfoundation.org/api/communities`;

    if (editFeed) {
      // console.log('inside submit edit');
      const formData = new FormData();
      if (img) {
        formData.append('feedPhoto', img);
      }
      formData.append('title', title);
      formData.append('description', description);
      formData.append('status', status);
      formData.append('feed_type_id', 5);
      formData.append('subtitle', 'Disscusions group');
      await authorizePatchMultipart(`mobileApp/feed/${editFeedId}`, formData)
        .then((response1) => {
          a.get(baseURL)
            .then((response) => {
              // handle success
              // console.log('response', response.data)
              if (response.data.status === 200) {
                setData(response.data)
              } else {
                setData([])
              }
            })
            .catch((error) => {
              if (error.response) {
                // The request was made and the server responded with a status code
                console.log('error1', error.response.data);
              } else if (error.request) {
                // The request was made but no response was received
                console.log('error2', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('error3', error.message);
              }
              setData([])
            });
          setShowForm(false);
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'success',
            titleText: response1.message,
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .catch((err) => {
          console.log('mobileAppFeed error', err);
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: err.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append('feedPhoto', img);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('feed_type_id', 5);
    formData.append('subtitle', 'Disscusions group');
    // console.log('feed data', formData);
    await authorizePostMultipart('mobileApp/feed', formData)
      .then((response1) => {
        a.get(baseURL)
          .then((response) => {
            // handle success
            // console.log('response', response.data)
            if (response.data.status === 200) {
              setData(response.data)
            } else {
              setData([])
            }
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              console.log('error1', error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              console.log('error2', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('error3', error.message);
            }
            setData([])
          });
        setShowForm(false);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response1.message,
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false);
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {loading ? (
              <>
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {showForm ? (
                  <div className='ml-5'>
                      <form method='post' className='pt-3' onSubmit={onSubmitFeed}>
                      <h5 className="title">Add Community</h5>
                      <div className='col-lg-8 col-sm-11'>
                        {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                        <div className='form-group'>
                          <label htmlFor='subject'>Title</label>
                          <input
                            name='title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            className='form-control form-control-lg'
                          />
                        </div>
                      </div>
                        <div className='col-lg-8 col-sm-11'>
                          <div className='form-group mt-4 mb-4'>
                            <label className='mr-2' htmlFor='subject'>Image</label>
                            <input
                              type="file"
                              name='img'
                              accept="image/*"
                              multiple={false}
                              onChange={handleImgChange}
                              required={editFeed ? false : true}
                            // className='form-control form-control-lg'
                            />
                            <img src={imgPreview} width={150} height={150} />
                          </div>
                        </div>
                      {editFeed && (
                          <div className='col-lg-8 col-sm-11'>
                            <div className='form-group'>
                              <label htmlFor='subject'>Status</label>
                              <select value={status} onChange={(e) => setStatus(e.target.value)} className="custom-select" id="inputGroupSelect01">
                                <option>Choose...</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                            </div>
                          </div>
                      )}
                        <div className='col-lg-8 col-sm-11'>
                          <div className='form-group'>
                            <label htmlFor='subject'>Description</label>
                            <textarea
                              name="description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                              className='form-control form-control-lg'></textarea>
                          </div>
                        </div>

                      <div className='col-lg-8 col-sm-11'>
                        {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                        <div className='form-group'>
                          <button
                            type='submit'
                            className="btn btn-primary">{editFeed ? 'Edit Post' : 'Submit'}</button>
                        </div>
                        <div className='form-group'>
                          <button
                            onClick={() => setShowForm(false)}
                            className="btn btn-danger">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    <div className="card-header">
                          <button onClick={() => {
                            setShowForm(true)
                            setEditFeed(false)
                          }} className="btn btn-primary btn-icon btn-round btn-lg">
                            <i className="fas fa-plus"></i>
                          </button>
                          <h5 className="title mt-3">Mobile App Communities</h5>
                    </div>
                    <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="text-primary">
                                <th>#</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th colSpan={2}></th>
                              </thead>
                              <tbody>
                                {data.data.results.length >= 1 ? (
                                  <>
                                    {data.data.results.map((item, index) => {
                                      const sdate = item.created_on.split('T')
                                      item.created_on = new Date(sdate[0]).toLocaleString('en-us', { month: 'short', year: 'numeric', day: '2-digit' });
                                      return (
                                        <tr key={index}>
                                          <td><img src={item.image} width={100} height={100} /></td>
                                          <td>{item.title}</td>
                                          <td>{item.description}</td>
                                          <td>{item.status === '1' ? 'Active' : 'In-active'}</td>
                                          <td className="text-right">
                                            <button onClick={() => {
                                              setStatus(item.status)
                                              setTitle(item.title)
                                              setDescription(item.description)
                                              setImgPreview(item.image)
                                              setEditFeed(true)
                                              setShowForm(true)
                                            }} className="btn btn-primary btn-circle">
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          </td>
                                          <td className="text-right">
                                            <button onClick={async () => {
                                              setLoading(true)
                                              await authorizeDeleteRequest(`mobileApp/feed/${item.id}`)
                                                .then((response1) => {
                                                  const a = axios.create({
                                                    headers: {
                                                      'Content-Type': 'application/json',
                                                      'X-Api-Key': 'VFpZ2rNwQbFz9cS-fiJm1vX_HBrJHq_NyBuAeWgnhBU',
                                                    },
                                                  });
                                                  let baseURL = `https://admin.rapehurtsfoundation.org/api/communities`;
                                                  a.get(baseURL)
                                                    .then((response) => {
                                                      // handle success
                                                      // console.log('response', response.data)
                                                      if (response.data.status === 200) {
                                                        setData(response.data)
                                                      } else {
                                                        setData([])
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      if (error.response) {
                                                        // The request was made and the server responded with a status code
                                                        console.log('error1', error.response.data);
                                                      } else if (error.request) {
                                                        // The request was made but no response was received
                                                        console.log('error2', error.request);
                                                      } else {
                                                        // Something happened in setting up the request that triggered an Error
                                                        console.log('error3', error.message);
                                                      }
                                                      setData([])
                                                    });
                                                  Swal.fire({
                                                    position: 'top-end',
                                                    toast: true,
                                                    width: 600,
                                                    // background: '#F47E52',
                                                    icon: 'success',
                                                    titleText: response1.message,
                                                    showConfirmButton: false,
                                                    timer: 2500,
                                                  });
                                                })
                                                .catch((err) => {
                                                  // console.log('mobileAppFeed error', err);
                                                  Swal.fire({
                                                    position: 'top-end',
                                                    toast: true,
                                                    width: 600,
                                                    // background: '#F47E52',
                                                    icon: 'error',
                                                    titleText: err.message,
                                                    showConfirmButton: false,
                                                    timer: 2500,
                                                  });
                                                });
                                              setLoading(false);
                                            }} className="btn btn-danger btn-circle">
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td className='text-center' colSpan={6}>
                                      Communities  will appear here
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileAppCommunities

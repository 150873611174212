import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';
import authorizePatchRequestWOT from '../api/authorizePatchRequestWOT';
import logo from '../assets/logo.png'

const SignIn = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isForgotPwd, setisForgotPwd] = useState(false)

  const onSuccess = async (res) => {
    setLoading(true);
    const userObject = jwt_decode(res.credential);
    // console.log('userObject', userObject);
    const dt = {
      firstName: userObject.given_name,
      lastName: userObject.family_name,
      email: userObject.email,
      password: userObject.aud,
      avatar: userObject.picture,
    }
    await authorizePostRequestWOT('user/google', dt)
      .then(response => {
        // console.log('login message', response);
        if (Number(response.user.activated) === 0) {
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: 'Your account is deactivated, Kindly contact an Admin',
            showConfirmButton: true,
            timer: 5000,
          });
          return;
        } else {
          localStorage.setItem('RHFAdmintoken', response.token);
          localStorage.setItem('RHFAdminUser', JSON.stringify(response.user));
          return navigate("/");
        }
      })
      .catch((error) => {
        // console.log('error logging in', error)
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: error.message,
          showConfirmButton: true,
          timer: 5000,
        });
      });
    setLoading(false);
  };
  const onFailure = (res) => {
    // console.log('Login failed: res:', res);
    Swal.fire({
      position: 'top-end',
      toast: true,
      width: 600,
      // background: '#F47E52',
      icon: 'error',
      titleText: "Something went wrong, refresh page or try later",
      showConfirmButton: true,
      timer: 5000,
    });
    // alert(
    //   `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
    // );
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dt = {
      email: email,
      password: password,
    }
    if (isForgotPwd) {
      await authorizePatchRequestWOT('user/forgotPassword', { email: email })
        .then(response => {
          // console.log('login message', response);
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'success',
            titleText: response.message,
            showConfirmButton: true,
            timer: 5000,
          });
        })
        .catch((error) => {
          // console.log('error logging in', error)
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: error.message,
            showConfirmButton: true,
            timer: 5000,
          });
        });
    } else {
      await authorizePostRequestWOT('user/login', dt)
        .then(response => {
          // console.log('login message', response);
          if (Number(response.user.activated) === 0) {
            Swal.fire({
              position: 'top-end',
              toast: true,
              width: 600,
              // background: '#F47E52',
              icon: 'error',
              titleText: 'Your account is deactivated, Kindly contact an Admin',
              showConfirmButton: true,
              timer: 5000,
            });
            return;
          } else {
            localStorage.setItem('RHFAdmintoken', response.token);
            localStorage.setItem('RHFAdminUser', JSON.stringify(response.user));
            return navigate("/");
          }
        })
        .catch((error) => {
          // console.log('error logging in', error)
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: error.message,
            showConfirmButton: true,
            timer: 5000,
          });
        });
    }
    setLoading(false);
  };
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#832a84', minHeight: '100vh' }}>
      <div className="col-lg-5">
        <div className="card">
          <div className="card-body">
            <div className="text-center mb-4">
              <img className="mb-4" src={logo} alt="" width="80" height="70" />
              <h1 className="h3 mb-3 font-weight-normal">Rape Hurts Foundation</h1>
              <p>Sign In For Admins Only</p>
            </div>
            {loading ? (
              <>
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!isForgotPwd && (
                  <div className="form-floating mb-3 d-flex align-items-center justify-content-center">
                    <GoogleLogin
                      text='continue_with'
                      onSuccess={onSuccess}
                      onError={onFailure}
                      cancel_on_tap_outside={true}
                      auto_select={true}
                    />
                  </div>
                )}
                <p className="text-center text-primary">{isForgotPwd ? "Account Recovery" : "OR"}</p>
                <form method='post' onSubmit={onSubmitLogin}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" value={email} onChange={onChangeEmail} className="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                  </div>
                  {!isForgotPwd ? (
                    <>
                      <div className="form-group mb-3">
                        <label for="pwdInput">Password</label>
                        <input type="password" value={password} onChange={onChangePassword} className="form-control form-control-lg" id="pwdInput" aria-describedby="emailHelp" required />
                      </div>
                      <a className="small" onClick={() => {
                        setisForgotPwd(true)
                      }} href="#">Forgot Password?</a>
                    </>
                  ) : (
                    <a className="small" onClick={() => {
                      setisForgotPwd(false)
                    }} href="#">Login Instead ...</a>
                  )}
                  
                  <button className="btn btn-lg btn-primary btn-block" type="submit">{isForgotPwd ? "Send Me Temporary Password" : "Sign in"}</button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn

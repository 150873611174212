import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './pages/Error';
import Home from './pages/Home';
import SignIn from './pages/SignIn';

const App = () => {
  const [data, setdata] = useState([
    {
      path: "/",
      element: <Home page="dashboard" />,
      errorElement: <Error />,
    },
    {
      path: "/signin",
      element: <SignIn />,
      errorElement: <Error />,
    },
    {
      path: "/website/:id",
      element: <Home page="website" />,
      errorElement: <Error />,
    },
    {
      path: "/mobilefeed/:id",
      element: <Home page="mobilefeed" />,
      errorElement: <Error />,
    },
    {
      path: "/mobilecases/:id",
      element: <Home page="mobilecases" />,
      errorElement: <Error />,
    },
    {
      path: "/mobilecommunities",
      element: <Home page="mobilecommunities" />,
      errorElement: <Error />,
    },
    {
      path: "/accounts/:id",
      element: <Home page="accounts" />,
      errorElement: <Error />,
    },
    {
      path: "/profile",
      element: <Home page="profile" />,
      errorElement: <Error />,
    },
  ])

  const router = createBrowserRouter(data);

  return (
    <RouterProvider router={router} />
  );
}

export default App;

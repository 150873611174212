const Footer = () => {
  return (
    <footer className="footer">
      <div className=" container-fluid ">
        <nav>
          <ul>
            <li>
                RHF
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          &copy;
          {new Date().getFullYear()}
           <span className="pl-1">
            Rape Hurts Foundation.
            </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer

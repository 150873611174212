import React, { useState, useEffect } from 'react'
import { NavLink, Link } from "react-router-dom";

const Sidebar = ({ page }) => {
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState('0')

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      // console.log('localStorage.RHFAdmintoken', localStorage.RHFAdminUser)
      setLevel(JSON.parse(localStorage.RHFAdminUser).userlevel)
      // user level info
      // 3 - top most
      // 2 - intermediate
      // 1 - basic
      setLoading(false);
    }
    getData();
  }, []);

  return (
    <div className="sidebar" data-color="orange">
      <div className="logo">
        <Link to="/" className="simple-text logo-normal">
          Rape Hurts Foundation
        </Link>
      </div>
      <div className="sidebar-wrapper" id="sidebar-wrapper">
        {loading ? (
          <>
            <div className="loader m-5 text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <ul className="nav">
            <li className={page === 'dashboard' ? "active" : ""}>
              <NavLink to="/">
                <i className="now-ui-icons design_app"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>
            {/* <li className={page === 'website' ? "active" : ""}> */}
            {Number(level) === 3 && (
              <li>
                <a data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                  <i className="now-ui-icons business_globe"></i>
                  <p>Website Content</p>
                </a>
                <div className="dropdown-menu">
                  <NavLink to="/website/home" className="dropdown-item">Home</NavLink>
                  <div className="dropdown-divider"></div>
                  <NavLink to="/website/about" className="dropdown-item">About Us</NavLink>
                  <div className="dropdown-divider"></div>
                  {/* <NavLink to="/website/hellen" className="dropdown-item">Hellen's Story</NavLink>
              <div className="dropdown-divider"></div> */}
                  <NavLink to="/website/campaigns" className="dropdown-item">Campaigns</NavLink>
                  <div className="dropdown-divider"></div>
                  <NavLink to="/website/programs" className="dropdown-item">Program Areas</NavLink>
                </div>
              </li>
            )}
            {Number(level) === 3 || Number(level) === 2 ? (
              <>
                {/* <li className={page === 'mobilefeed' ? "active" : ""}> */}
                <li>
                  <a data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="now-ui-icons files_paper"></i>
                    <p>Mobile App Feed</p>
                  </a>
                  <div className="dropdown-menu">
                    <NavLink to="/mobilefeed/create" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Create Feed</NavLink>
                    <div className="dropdown-divider"></div>
                    <NavLink to="/mobilefeed/types" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Types of Feed</NavLink>
                  </div>
                </li>
                {/* <li className={page === 'mobilecases' ? "active" : ""}> */}
                <li>
                  <a data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="now-ui-icons business_briefcase-24"></i>
                    <p>Mobile App Cases</p>
                  </a>
                  <div className="dropdown-menu">
                    <NavLink to="/mobilecases/reports" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Reported Cases</NavLink>
                    <div className="dropdown-divider"></div>
                    <NavLink to="/mobilecases/types" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Types of Cases</NavLink>
                  </div>
                </li>
                <li className={page === 'mobilecommunities' ? "active" : ""}>
                  <NavLink to="/mobilecommunities">
                    <i className="now-ui-icons location_world"></i>
                    <p>Mobile App Communities</p>
                  </NavLink>
                </li>
                {/* <li className={page === 'accounts' ? "active" : ""}> */}

              </>
            ) : (<></>)}
            {Number(level) === 3 && (
              <li>
                <a data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                  <i className="now-ui-icons business_badge"></i>
                  <p>Admin Accounts</p>
                </a>
                <div className="dropdown-menu">
                  <NavLink to="/accounts/approved" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Approved</NavLink>
                  <div className="dropdown-divider"></div>
                  <NavLink to="/accounts/pending" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>In-Active</NavLink>
                </div>
              </li>
            )}
            {Number(level) === 1 && (
              <li>
              <a data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                <i className="now-ui-icons business_briefcase-24"></i>
                <p>Mobile App Cases</p>
              </a>
              <div className="dropdown-menu">
                <NavLink to="/mobilecases/reports" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Reported Cases</NavLink>
                <div className="dropdown-divider"></div>
                <NavLink to="/mobilecases/types" className={({ isActive, isPending }) => isActive ? "dropdown-item active" : "dropdown-item"}>Types of Cases</NavLink>
              </div>
            </li>
            )}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Sidebar

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import authorizeGetRequest from '../api/authorizeGetRequest';
import authorizeDeleteRequest from '../api/authorizeDeleteRequest';
import authorizePostRequest from '../api/authorizePostRequest';
import authorizePatchRequest from "../api/authorizePatchRequest";
import CasesModal from '../components/CasesModal';

const MobileAppCases = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [showForm, setshowForm] = useState(false);
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')
  const [isEdit, setisEdit] = useState(false)
  const [editId, setEditId] = useState('')
  const [modalData, setModalData] = useState()
  const [showReport, setShowReport] = useState(false)
  const [reportData, setReportData] = useState('')
  const [fileType, setFileType] = useState('')
  const [urgentCheck, setUrgentCheck] = useState('other')
  const [openClosedCheck, setOpenClosedCheck] = useState('2')
  const [searchvalue, setsearchvalue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [gotUserSearchDt, setgotUserSearchDt] = useState(false)
  const [searchDtSummary, setSearchDtSummary] = useState({
    closed: 0,
    open: 0,
  })
  const [filterUserCases, setFilterUserCases] = useState('0')
  const [searchRslt, setSearchRslt] = useState([])
  const [caseNotes, setCaseNotes] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (id === 'types') {
        await authorizeGetRequest('mobileApp/casetypes/0')
          .then((response) => {
            if (response.success === true) {
              // console.log('casestypes success', response.cases);
              if (response.cases !== 0) {
                setData(response.cases)
              } else {
                setData([])
              }
            } else {
              setData([])
              // console.log('adminFeed failed', response);
            }
          })
          .catch((err) => {
            setData([])
            console.log('adminFeed error', err);
          });
      } else if (id === 'reports') {
        await authorizeGetRequest('mobileApp/cases/0')
          .then((response) => {
            if (response.success === true) {
              // console.log('cases success', response.cases);
              if (response.cases !== 0) {
                setData(response.cases)
              } else {
                setData([])
              }
            } else {
              setData([])
              // console.log('adminFeed failed', response);
            }
          })
          .catch((err) => {
            setData([])
            console.log('adminFeed error', err);
          });
      }
      setLoading(false);
    }
    getData();
  }, [id]);

  const onSubmitCaseType = async (e) => {
    e.preventDefault();
    setLoading(true)
    const dt = {
      name: name,
      description: description,
    }
    if (isEdit) {
      await authorizePatchRequest(`mobileApp/casetype/${editId}`, dt)
        .then((response) => {
          if (response.cases !== 0) {
            setData(response.cases)
          } else {
            setData([])
          }
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'success',
            titleText: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setshowForm(false);
        })
        .catch((err) => {
          // console.log('mobileAppFeed error', err);
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: err.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
      setLoading(false)
      return;
    }
    await authorizePostRequest('mobileApp/casetypes', dt)
      .then((response) => {
        if (response.cases !== 0) {
          setData(response.cases)
        } else {
          setData([])
        }
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setshowForm(false);
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false)
  }

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setgotUserSearchDt(false)
    await authorizeGetRequest(`mobileApp/searchName/${searchvalue}`)
      .then((response) => {
        if (response.success === true) {
          console.log('search success', response.users);
          setIsSearching(true)
          if (response.users !== 0) {
            setSearchData(response.users)
          } else {
            setSearchData([])
          }
        } else {
          setSearchData([])
          // console.log('adminFeed failed', response);
        }
      })
      .catch((err) => {
        setSearchData([])
        console.log('filter error', err);
      });
    setData([])
    setLoading(false);
  }

  const updateStatusItem = async (dt) => {
    // console.log('dddt', dt)
    if (modalData === 'note') {
      await setCaseNotes(dt)
    } else {
      await setReportData(dt)
    }
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-lg-12">
          {loading ? (
            <>
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {id === 'types' && (
                <div className="card">
                  {showForm ? (
                    <div className='ml-5'>
                      <form method='post' className='p-3' onSubmit={onSubmitCaseType}>
                        <h5 className="title">Add Case Type</h5>

                        <div className='col-lg-8 col-sm-11'>
                          {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                          <div className='form-group'>
                            <label htmlFor='subject'>Name</label>
                            <input
                              name='name'
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                              className='form-control form-control-lg'
                            />
                          </div>
                        </div>
                        <div className='col-lg-8 col-sm-11'>
                          <div className='form-group'>
                            <label htmlFor='subject'>Description</label>
                            <textarea
                              name="description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                              className='form-control form-control-lg'></textarea>
                          </div>
                        </div>

                        <div className='col-lg-8 col-sm-11'>
                          {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                          <div className='form-group'>
                            <button
                              type='submit'
                              className="btn btn-primary">{isEdit ? 'Edit Details' : 'Submit'}</button>
                          </div>
                          <div className='form-group'>
                            <button
                              onClick={() => setshowForm(false)}
                              className="btn btn-danger">Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <>
                      <div className="card-header">
                        <button onClick={() => {
                          setshowForm(true)
                          setisEdit(false);
                          setEditId()
                          setName('')
                          setDescription('')
                        }} className="btn btn-primary btn-icon btn-round btn-lg">
                          <i className="fas fa-plus"></i>
                        </button>
                        <h5 className="title mt-3">Case Types</h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="text-primary">
                              <th>Name</th>
                              <th>Description</th>
                              <th>Created on</th>
                              <th colSpan={2}></th>
                            </thead>
                            <tbody>
                              {data.length >= 1 ? (
                                <>
                                  {data.map((item, index) => {
                                    const sdate = item.created_on.split('T')
                                    item.created_on = new Date(sdate[0]).toLocaleString('en-us', { month: 'short', year: 'numeric', day: '2-digit' });
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {item.name}
                                        </td>
                                        <td>
                                          {item.description}
                                        </td>
                                        <td className="text-right">
                                          {item.created_on}
                                        </td>
                                        <td className="text-right">
                                          <button onClick={() => {
                                            setisEdit(true)
                                            setEditId(item.id)
                                            setName(item.name)
                                            setDescription(item.description)
                                            setshowForm(true)
                                          }} className="btn btn-primary btn-circle">
                                            <i className="fas fa-edit"></i>
                                          </button>
                                        </td>
                                        <td className="text-right">
                                          <button onClick={async () => {
                                            setLoading(true)
                                            await authorizeDeleteRequest(`mobileApp/casetype/${item.id}`)
                                              .then((response) => {
                                                if (response.cases !== 0) {
                                                  setData(response.cases)
                                                } else {
                                                  setData([])
                                                }
                                                Swal.fire({
                                                  position: 'top-end',
                                                  toast: true,
                                                  width: 600,
                                                  // background: '#F47E52',
                                                  icon: 'success',
                                                  titleText: response.message,
                                                  showConfirmButton: false,
                                                  timer: 2500,
                                                });
                                              })
                                              .catch((err) => {
                                                Swal.fire({
                                                  position: 'top-end',
                                                  toast: true,
                                                  width: 600,
                                                  // background: '#F47E52',
                                                  icon: 'error',
                                                  titleText: err.message,
                                                  showConfirmButton: false,
                                                  timer: 2500,
                                                });
                                              });
                                            setLoading(false);
                                          }} className="btn btn-danger btn-circle">
                                            <i className="fas fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  }
                                  )}
                                </>
                              ) : (
                                <tr>
                                  <td className='text-center' colSpan={5}>
                                    Case types will appear here
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="d-flex">
                            {pagBack && (
                              <button onClick={() => {
                                if (feedData.length > 15) {
                                  const dt = feedData[feedData.length - 1]
                                  console.log('element', dt)
                                } else {
                                  setPagBack(false);
                                }
                              }} className="btn btn-primary btn-icon btn-round mr-2">
                                <i className="fas fa-chevron-left"></i>
                              </button>
                            )}
                            {pagForward && (
                              <button onClick={() => {
                                if (feedData.length > 15) {

                                } else {
                                  setPagForward(false);
                                }
                              }} className="btn btn-primary btn-icon btn-round">
                                <i className="fas fa-chevron-right"></i>
                              </button>
                            )}
                          </div> */}
                      </div>
                    </>
                  )}
                </div>
              )}
              {id === 'reports' && (
                <>
                  {showForm ? (
                    <div className='ml-5'>
                      <form method='post' className='p-3' onSubmit={onSubmitCaseType}>
                        <h5 className="title">Add Case Type</h5>

                        <div className='col-lg-8 col-sm-11'>
                          {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                          <div className='form-group'>
                            <label htmlFor='subject'>Name</label>
                            <input
                              name='name'
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                              className='form-control form-control-lg'
                            />
                          </div>
                        </div>
                        <div className='col-lg-8 col-sm-11'>
                          <div className='form-group'>
                            <label htmlFor='subject'>Description</label>
                            <textarea
                              name="description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                              className='form-control form-control-lg'></textarea>
                          </div>
                        </div>

                        <div className='col-lg-8 col-sm-11'>
                          {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                          <div className='form-group'>
                            <button
                              type='submit'
                              className="btn btn-primary">{isEdit ? 'Edit Details' : 'Submit'}</button>
                          </div>
                          <div className='form-group'>
                            <button
                              onClick={() => setshowForm(false)}
                              className="btn btn-danger">Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <>
                      {showReport ? (
                        <>
                          <div className="content">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="card">
                                  <div className="card-header">
                                    <button onClick={() => {
                                      setShowReport(false)
                                    }} className="btn btn-primary btn-icon btn-round">
                                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                                    </button>
                                    <button data-toggle="modal" data-target="#caseModal" onClick={() => {
                                      setModalData(reportData)
                                    }} class="btn btn-primary btn-round ml-2" type="button">
                                      <i class="now-ui-icons design_scissors"></i> Change status of case
                                    </button>
                                    <button data-toggle="modal" data-target="#caseModal" onClick={() => {
                                      setModalData('note')
                                      // console.log('noteee', reportData)
                                    }} class="btn btn-primary btn-round ml-2" type="button">
                                      <i class="fa fa-edit"></i> Add note
                                    </button>
                                    <h5 className="title">Case Details</h5>
                                  </div>
                                  <div className="card-body">
                                    <Accordion preExpanded={['62fe882e937e580009a46e2f']} allowZeroExpanded>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            File Record
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <>
                                            {fileType === 'vid' && (
                                              <div class="row justify-content-center align-items-center">
                                                <video width="400" height="400" controls>
                                                  <source src={reportData.recording_url} />
                                                  Your browser does not support HTML video.
                                                </video>
                                                {/* <ReactPlayer width="600" height="600" controls url={fileData} /> */}
                                              </div>
                                            )}
                                            {fileType === 'audio' && (
                                              <div class="row justify-content-center align-items-center">
                                                <audio width="400" controls autoPlay>
                                                  <source src={reportData.recording_url} />
                                                  Your browser does not support the audio element.
                                                </audio>
                                              </div>
                                            )}
                                            {fileType === 'doc' && (
                                              <div class="row justify-content-center align-items-center">
                                                <iframe src={reportData.recording_url} className="h-50" width="100%"></iframe>
                                              </div>
                                            )}
                                            {fileType === 'img' && (
                                              <div class="row justify-content-center align-items-center">
                                                <img className='mx-auto mt-2' src={reportData.recording_url} style={{ height: '17rem', objectFit: 'cover' }} />
                                              </div>
                                            )}
                                          </>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem uuid='62fe882e937e580009a46e2f'>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Case Data
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <>
                                            <h5 className="title mb-0 pb-0">Case Description</h5>
                                            <p>{reportData.description || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Case Status</h5>
                                            <p>{reportData.status === '1' ? 'Closed' : reportData.status === '2' ? 'In review' : reportData.status === '3' ? 'Delayed' : 'Open'}</p>
                                            <h5 className="title mb-0 pb-0">Subcase Name</h5>
                                            <p>{reportData.subcaseName || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Subcase Description</h5>
                                            <p>{reportData.subcaseDescription || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Date of Incident</h5>
                                            <p>{reportData.date_of_incident || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Urgent</h5>
                                            <p>{reportData.urgent || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Abuses that occured</h5>
                                            <p>{reportData.abuses_that_occured || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Area of incident</h5>
                                            <p>{reportData.area_incident || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Benefits exchanged</h5>
                                            <p>{reportData.benefits_exchanged || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Case type</h5>
                                            <p>{reportData.caseName || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Days spent abused or trafficked</h5>
                                            <p>{reportData.days_spent_abused_trafficked || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Knows the collaborator</h5>
                                            <p>{reportData.do_you_know_the_collaborator || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Effects of abuse</h5>
                                            <p>{reportData.effects_of_abuse || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Location of incident</h5>
                                            <p>{reportData.location_incident || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Previous description</h5>
                                            <p>{reportData.previous_description || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Previous docs</h5>
                                            <p>{reportData.previous_docs || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Previous incident</h5>
                                            <p>{reportData.previous_incident || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Report state</h5>
                                            <p>{reportData.report_state || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Time of incident</h5>
                                            <p>{reportData.time_of_incident || "__"}</p>
                                            <h5 className="title mb-0 pb-0">type of abduction</h5>
                                            <p>{reportData.type_abduction || "__"}</p>
                                          </>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Services
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <>
                                            <h5 className="title mb-0 pb-0">Service Name</h5>
                                            <p>{reportData.services || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Service provider</h5>
                                            <p>{reportData.serviceProvider || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Service provider phone</h5>
                                            <p>{reportData.serviceProviderTelephone || "__"}</p>
                                            <h5 className="title mb-0 pb-0">Service provider email</h5>
                                            <p>{reportData.serviceProviderEmail || "__"}</p>
                                          </>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card card-user">
                                  <img className="card-img-top mb-0" style={{ height: '15rem', objectFit: 'cover' }} src={reportData.userPhoto} alt="img" />
                                  <div className="card-body">
                                    <h4 className="card-title">{`${reportData.userFirstName} ${reportData.userLastName}`}</h4>
                                    <h6 className="card-subtitle mb-2 text-muted">{reportData.userPhone}</h6>
                                    <p className="card-text">{reportData.userEmail}</p>
                                    <p className="card-text">{reportData.userGender}</p>
                                    {reportData.urgent === 'Yes' ? (
                                      <span className="badge badge-danger ml-2">Urgent</span>
                                    ) : (
                                      <span className="badge badge-warning ml-2">Not Urgent</span>
                                    )}
                                  </div>
                                </div>
                                <div className="card card-user">
                                  <div className="card-body">
                                    <h4 className="card-title">Case notes</h4>
                                    {caseNotes.length >= 1 ? (
                                      <>
                                        {caseNotes.map((item, index) => (
                                          <p key={index} className="card-text">{item.note}</p>
                                        ))}
                                      </>
                                    ) : (
                                      <p className="card-text">Case notes will appear here</p>
                                    )}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <CasesModal data={modalData} caseId={reportData.id} updateStatusItem={updateStatusItem} />
                        </>
                      ) : (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <div className="card">
                              <div className="card-body mt-0">
                                <form method='post' className='p-3' onSubmit={onSubmitSearch}>
                                  <div className='col-sm-11 d-flex'>
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text"><i class="fa fa-search"></i></div>
                                      </div>
                                      <input type="text" value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} required class="form-control" placeholder="Search names" />
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-round ml-3">Search</button>
                                  </div>
                                  <div className='col-sm-11'>
                                    {isSearching ? (
                                      <>
                                        {gotUserSearchDt ? (
                                          <>
                                            <p className="text-primary">{`Cases available (${data.length})`}</p>
                                            <p className="text-primary">{`Open Cases (${searchDtSummary.open})`}</p>
                                            <p className="text-primary">{`Closed Cases (${searchDtSummary.closed})`}</p>
                                            <p>FIlter cases by :</p>
                                            <input type="checkbox"
                                              onChange={async (e) => {
                                                setLoading(true)
                                                setFilterUserCases('0')
                                                setData(searchRslt)
                                                setLoading(false)
                                              }} checked={filterUserCases === '0' ? true : false} id="vehicle11" name="vehicle1" value="0" />
                                            <label className="ml-2 mr-4" htmlFor="vehicle11">All cases</label>
                                            <input type="checkbox"
                                              onChange={async (e) => {
                                                setLoading(true)
                                                setFilterUserCases('1')
                                                const ssdt = searchRslt;
                                                let newssdt = []
                                                ssdt.forEach(element => {
                                                  if (element.status === '0') {
                                                    newssdt.push(element)
                                                  }
                                                });
                                                setData(newssdt)
                                                setLoading(false)
                                              }} checked={filterUserCases === '1' ? true : false} id="vehicle12" name="vehicle1" value="0" />
                                            <label className="ml-2 mr-4" htmlFor="vehicle12">Open cases</label>
                                            <input className="ml-2"
                                              onChange={async (e) => {
                                                setLoading(true)
                                                setFilterUserCases('2')
                                                const ssdt = searchRslt;
                                                let newssdt = []
                                                ssdt.forEach(element => {
                                                  if (element.status === '1') {
                                                    newssdt.push(element)
                                                  }
                                                });
                                                setData(newssdt)
                                                setLoading(false)
                                              }} checked={filterUserCases === '2' ? true : false} type="checkbox" id="vehicle13" name="vehicle2" value="1" />
                                            <label className="ml-2" htmlFor="vehicle13">Closed cases</label>
                                          </>
                                        ) : (
                                          <>
                                            {searchData.length >= 1 ? (
                                              <>
                                                <p className="text-primary">{`Search results (${searchData.length})`}</p>
                                                {searchData.map((item, index) => (
                                                  <div key={index} style={{ cursor: 'pointer' }} onClick={async () => {
                                                    setLoading(true)
                                                    setData([])
                                                    // setIsSearching(false)
                                                    await authorizeGetRequest(`mobileApp/userCases/${item.id}`)
                                                      .then((response) => {
                                                        if (response.success === true) {
                                                          // console.log('cases filter success', response.cases);
                                                          if (response.cases !== 0) {
                                                            setData(response.cases)
                                                            setSearchRslt(response.cases)
                                                            let openC = 0, closedC = 0
                                                            response.cases.forEach(element => {
                                                              // console.log('case dt', element)
                                                              if (element.status === '0') {
                                                                openC = openC + 1
                                                              } else {
                                                                closedC = closedC + 1
                                                              }
                                                            });
                                                            setSearchDtSummary({
                                                              closed: closedC,
                                                              open: openC,
                                                            })
                                                          } else {
                                                            setData([])
                                                          }
                                                        } else {
                                                          setData([])
                                                          // console.log('adminFeed failed', response);
                                                        }
                                                      })
                                                      .catch((err) => {
                                                        setData([])
                                                        console.log('filter error', err);
                                                      });
                                                    setgotUserSearchDt(true)
                                                    setLoading(false)
                                                  }} className="col-lg-12 mb-3 d-flex">
                                                    <img src={item.userPhoto} width={150} height={150} alt="img" />
                                                    <div className="ml-3">
                                                      <p>{`${item.userFirstName} ${item.userLastName}`}</p>
                                                      <p>{item.userEmail}</p>
                                                      <p>{item.userPhone}</p>
                                                      <p>{`Gender : ${item.userGender}`}</p>
                                                    </div>
                                                  </div>
                                                ))}
                                              </>
                                            ) : (
                                              <p>Search results will appear here</p>
                                            )}
                                          </>
                                        )}

                                      </>
                                    ) : (
                                      <>
                                        <br />
                                        <span className="text-primary">Filter options</span>
                                        <br />
                                        <input type="checkbox"
                                          onChange={async (e) => {
                                            setLoading(true)
                                            setUrgentCheck('')
                                            setsearchvalue('')
                                            setOpenClosedCheck('0')
                                            await authorizeGetRequest('mobileApp/filter/status/0/0')
                                              .then((response) => {
                                                if (response.success === true) {
                                                  console.log('cases filter success', response.cases);
                                                  if (response.cases !== 0) {
                                                    setData(response.cases)
                                                  } else {
                                                    setData([])
                                                  }
                                                } else {
                                                  setData([])
                                                  // console.log('adminFeed failed', response);
                                                }
                                              })
                                              .catch((err) => {
                                                setData([])
                                                console.log('filter error', err);
                                              });
                                            setLoading(false)
                                          }} checked={openClosedCheck === '0' ? true : false} id="vehicle1" name="vehicle1" value="0" />
                                        <label className="ml-2" htmlFor="vehicle1">Open cases</label>
                                        <input className="ml-2"
                                          onChange={async (e) => {
                                            setLoading(true)
                                            setUrgentCheck('')
                                            setsearchvalue('')
                                            setOpenClosedCheck('1')
                                            await authorizeGetRequest('mobileApp/filter/status/1/0')
                                              .then((response) => {
                                                if (response.success === true) {
                                                  console.log('cases filter success', response.cases);
                                                  if (response.cases !== 0) {
                                                    setData(response.cases)
                                                  } else {
                                                    setData([])
                                                  }
                                                } else {
                                                  setData([])
                                                  // console.log('adminFeed failed', response);
                                                }
                                              })
                                              .catch((err) => {
                                                setData([])
                                                console.log('filter error', err);
                                              });
                                            setLoading(false)
                                          }} checked={openClosedCheck === '1' ? true : false} type="checkbox" id="vehicle2" name="vehicle2" value="1" />
                                        <label className="ml-2" htmlFor="vehicle2">Closed cases</label>
                                        <br />
                                        <hr />
                                        <input type="checkbox" checked={urgentCheck === 'yes' ? true : false}
                                          onChange={async (e) => {
                                            setLoading(true)
                                            setOpenClosedCheck('2')
                                            setsearchvalue('')
                                            setUrgentCheck(e.target.value)
                                            await authorizeGetRequest('mobileApp/filter/urgent/Yes/0')
                                              .then((response) => {
                                                if (response.success === true) {
                                                  // console.log('cases filter success', response.cases);
                                                  if (response.cases !== 0) {
                                                    setData(response.cases)
                                                  } else {
                                                    setData([])
                                                  }
                                                } else {
                                                  setData([])
                                                  // console.log('adminFeed failed', response);
                                                }
                                              })
                                              .catch((err) => {
                                                setData([])
                                                console.log('filter error', err);
                                              });
                                            setLoading(false)
                                          }} id="vehicle3" name="vehicle3" value="yes" />
                                        <label className="ml-2" htmlFor="vehicle3">Urgent cases</label>
                                        <input
                                          onChange={async (e) => {
                                            setLoading(true)
                                            setOpenClosedCheck('2')
                                            setsearchvalue('')
                                            setUrgentCheck(e.target.value)
                                            await authorizeGetRequest('mobileApp/filter/urgent/No/0')
                                              .then((response) => {
                                                if (response.success === true) {
                                                  // console.log('cases filter success', response.cases);
                                                  if (response.cases !== 0) {
                                                    setData(response.cases)
                                                  } else {
                                                    setData([])
                                                  }
                                                } else {
                                                  setData([])
                                                  // console.log('adminFeed failed', response);
                                                }
                                              })
                                              .catch((err) => {
                                                setData([])
                                                console.log('filter error', err);
                                              });
                                            setLoading(false)
                                          }} className="ml-2" type="checkbox" checked={urgentCheck === 'no' ? true : false} id="vehicle4" name="vehicle4" value="no" />
                                        <label className="ml-2" htmlFor="vehicle4">Not Urgent</label>
                                      </>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          {data.length >= 1 ? (
                            <>
                              {data.map((item, index) => {
                                const sdate = item.created_on.split('T')
                                item.created_on = new Date(sdate[0]).toLocaleString('en-us', { month: 'short', year: 'numeric', day: '2-digit' });
                                return (
                                  <div key={index} className="col-lg-4 mb-3">
                                    <div className="card" style={{ cursor: 'pointer', minHeight: '100%' }} onClick={async () => {
                                      setReportData(item)
                                      // console.log('report data', item)
                                      await authorizeGetRequest(`mobileApp/casenotes/${item.id}`)
                                        .then((response) => {
                                          if (response.success === true) {
                                            // console.log('casestypes success', response.cases);
                                            if (response.notes !== 0) {
                                              setCaseNotes(response.notes)
                                            } else {
                                              setCaseNotes([])
                                            }
                                          } else {
                                            setCaseNotes([])
                                            // console.log('adminFeed failed', response);
                                          }
                                        })
                                        .catch((err) => {
                                          setCaseNotes([])
                                          console.log('adminFeed error', err);
                                        });
                                      const dt = item.recording_url.split('.')
                                      let exts = dt[dt.length - 1]
                                      exts = exts.toLowerCase();
                                      if (exts === 'mp4' || exts === 'webm' || exts === 'mpg' || exts === 'mp2' || exts === 'mpeg' || exts === 'mpe' || exts === 'mpv' || exts === 'ogg' || exts === 'm4p' || exts === 'm4v' || exts === 'avi' || exts === 'mp4' || exts === 'mwv' || exts === 'mov' || exts === 'qt' || exts === 'flv' || exts === 'swf' || exts === 'avchd') {
                                        // video
                                        setFileType('vid')
                                      } else if (exts === 'doc' || exts === 'docx' || exts === 'odt' || exts === 'pdf' || exts === 'xls' || exts === 'xlsx' || exts === 'ppt' || exts === 'pptx' || exts === 'txt') {
                                        //docs
                                        setFileType('doc')

                                      } else if (exts === 'jpg' || exts === 'png' || exts === 'gif' || exts === 'webp' || exts === 'tiff' || exts === 'psd' || exts === 'raw' || exts === 'bmp' || exts === 'heif' || exts === 'indd' || exts === 'jpeg' || exts === 'svg' || exts === 'ai' || exts === 'eps') {
                                        // images
                                        setFileType('img')
                                      } else {
                                        // audio
                                        setFileType('audio')
                                      }
                                      setShowReport(true)
                                    }}>
                                      <img className="card-img-top mb-0" style={{ height: '13rem', objectFit: 'cover' }} src={item.userPhoto} alt="img" />
                                      <div className="card-body mt-0">
                                        <h4 className="card-title">{`${item.userFirstName} ${item.userLastName}`}</h4>
                                        <h6 className="card-subtitle mb-2 text-muted">{item.userPhone}</h6>
                                        <p className="card-text">{item.description}</p>
                                        {/* <a href="#" className="btn btn-primary">Read More</a> */}
                                      </div>
                                      <div className="card-footer text-muted mb-2">
                                        {item.date_of_incident}
                                        <br />
                                        {item.urgent === 'Yes' ? (
                                          <span className="badge badge-danger ml-2">Urgent</span>
                                        ) : (
                                          <span className="badge badge-warning ml-2">Not Urgent</span>
                                        )}

                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              )}
                            </>
                          ) : (
                            <div className="col-lg-12 mb-3">
                              <p className='text-center text-primary'>
                                Reported Cases will appear here
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileAppCases

import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import authorizeGetRequest from '../api/authorizeGetRequest';
import authorizeDeleteRequest from '../api/authorizeDeleteRequest';
import authorizePostMultipart from '../api/authorizePostMultipart';
import authorizePatchMultipart from '../api/authorizePatchMultipart';
import authorizePostRequest from '../api/authorizePostRequest';
import authorizePatchRequest from "../api/authorizePatchRequest";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

const MobileAppFeed = () => {
  const { id } = useParams();
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(true);
  const [feedData, setFeedData] = useState([]);
  const [feedTypeId, setFeedTypeId] = useState('')
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [description, setDescription] = useState("")
  const [img, setImg] = useState('')
  const [imgPreview, setImgPreview] = useState('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
  const [feedTypedata, setFeedTypeData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState('')
  const [editFeed, setEditFeed] = useState(false)
  const [editFeedId, setEditFeedId] = useState()
  const [pagBack, setPagBack] = useState(false)
  const [pagForward, setPagForward] = useState(false)
  const [feedType, setFeedType] = useState('')
  const [feedDescription, setFeedDescription] = useState('')
  const [feedTypeUpdates, setfeedTypeUpdates] = useState('')

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      setEditFeed(false)
      setErrorMessage('')
      setShowForm(false);
      if (id === 'create') {
        await authorizeGetRequest('mobileApp/feed/admin/0')
          .then((response) => {
            if (response.success === true) {
              // console.log('adminFeed success', response.feeds);
              if (response.feeds !== 0) {
                setFeedData(response.feeds)
              } else {
                setFeedData([])
              }
            } else {
              setFeedData([])
              // console.log('adminFeed failed', response);
            }
          })
          .catch((err) => {
            setFeedData([])
            console.log('adminFeed error', err);
          });
      } else if (id === 'types') {
        // setTitle('Mobile App Feed / Post Types')
      }
      await authorizeGetRequest('mobileApp/feed/types/0')
        .then((response) => {
          // console.log('mobileAppFeedTypes success', response);
          if (response.feedTypes !== 0) {
            setFeedTypeData(response.feedTypes)
          } else {
            setFeedTypeData([])
          }

        })
        .catch((err) => {
          setFeedTypeData([])
          console.log('mobileAppFeedType error', err);
        });
      setLoading(false)
    }
    getData();
  }, [id])

  const handleImgChange = e => {
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg(e.target.files[0]);
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
  }
  const onSubmitFeed = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (editFeed) {
      setErrorMessage('')
      const formData = new FormData();
      if (img) {
        formData.append('feedPhoto', img);
      }
      formData.append('feed_type_id', feedTypeId);
      formData.append('title', title);
      if (subTitle){
        formData.append('subtitle', subTitle);
      }
      if (description){
        formData.append('description', description);
      }
      if (status){
        formData.append('status', status);
      }
      await authorizePatchMultipart(`mobileApp/feed/${editFeedId}`, formData)
      .then((response1) => {
        authorizeGetRequest('mobileApp/feed/admin/0')
          .then((response) => {
            if (response.success === true) {
              // console.log('adminFeed success', response.feeds);
              if (response.feeds !== 0) {
                setFeedData(response.feeds)
              } else {
                setFeedData([])
              }
            } else {
              setFeedData([])
              console.log('adminFeed failed', response);
            }
          })
          .catch((err) => {
            setFeedData([])
            console.log('adminFeed error', err);
          });
        setShowForm(false);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response1.message,
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((err) => {
        console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
      setLoading(false);
      return;
    }
    if (!img || !feedTypeId || !description) {
      setErrorMessage('All fields cannot be empty')
      setLoading(false)
      return;
    }
    setErrorMessage('')
    const formData = new FormData();
    formData.append('feedPhoto', img);
    formData.append('feed_type_id', feedTypeId);
    formData.append('title', title);
    formData.append('subtitle', subTitle);
    formData.append('description', description);
    // console.log('feed data', formData);
    await authorizePostMultipart('mobileApp/feed', formData)
      .then((response1) => {
        authorizeGetRequest('mobileApp/feed/admin/0')
          .then((response) => {
            if (response.success === true) {
              // console.log('adminFeed success', response.feeds);
              if (response.feeds !== 0) {
                setFeedData(response.feeds)
              } else {
                setFeedData([])
              }
            } else {
              setFeedData([])
              console.log('adminFeed failed', response);
            }
          })
          .catch((err) => {
            setFeedData([])
            console.log('adminFeed error', err);
          });
        setShowForm(false);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response1.message,
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((err) => {
        console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false);
  }
  const onSubmitFeedType = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log('feed submited', feedDescription, feedType)
    if (editFeed) {
      await authorizePatchRequest(`mobileApp/feedType/${editFeedId}`, { type: feedType, description: feedDescription, status: status, updates_avaliable: feedTypeUpdates })
        .then((response) => {
          authorizeGetRequest('mobileApp/feed/types/0')
            .then((response) => {
              // console.log('mobileAppFeedTypes success', response);
              if (response.feedTypes !== 0) {
                setFeedTypeData(response.feedTypes)
              } else {
                setFeedTypeData([])
              }
            })
            .catch((err) => {
              setFeedTypeData([])
              // console.log('mobileAppFeedType error', err);
            });
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'success',
            titleText: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setShowForm(false);
        })
        .catch((err) => {
          // console.log('mobileAppFeed error', err);
          Swal.fire({
            position: 'top-end',
            toast: true,
            width: 600,
            // background: '#F47E52',
            icon: 'error',
            titleText: err.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
      setLoading(false);
      return;
    }
    await authorizePostRequest('mobileApp/feedType', { type: feedType, description: feedDescription })
      .then((response) => {
        authorizeGetRequest('mobileApp/feed/types/0')
          .then((response) => {
            // console.log('mobileAppFeedTypes success', response);
            if (response.feedTypes !== 0) {
              setFeedTypeData(response.feedTypes)
            } else {
              setFeedTypeData([])
            }
          })
          .catch((err) => {
            setFeedTypeData([])
            // console.log('mobileAppFeedType error', err);
          });
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setShowForm(false);
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false);
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {loading ? (
              <>
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {id === 'create' && (
                  <>
                    {showForm ? (
                      <div className='ml-5'>
                        {feedTypedata.length >= 1 ? (
                          <form method='post' className='p-3' onSubmit={onSubmitFeed}>
                            <h5 className="title">Create Post</h5>
                            <div className='col-lg-8 col-sm-11 mt-4'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                              <div className='form-group'>
                                <label htmlFor='subject'>Feed Type</label>
                                <select value={feedTypeId} onChange={(e) => setFeedTypeId(e.target.value)} className="form-control form-control-lg" id="inputGroupSelect01" required>
                                  <option>Choose...</option>
                                  {feedTypedata.map((element, index) => {
                                    // console.log('element', element.type)
                                    return (
                                      <option key={index} value={element.id}>{element.type}</option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>Title</label>
                                <input
                                  name='title'
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                              <div className='col-lg-8 col-sm-11'>
                                  <div className='form-group'>
                                    <label htmlFor='subject'>Sub Title</label>
                                    <input
                                      name='subtitle'
                                      value={subTitle}
                                      onChange={(e) => setSubTitle(e.target.value)}
                                      required
                                      className='form-control form-control-lg'
                                    />
                                  </div>
                              </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group mt-4 mb-4'>
                                <label className='mr-2' htmlFor='subject'>Image</label>
                                <input
                                  type="file"
                                  name='img'
                                  accept="image/*"
                                  multiple={false}
                                  onChange={handleImgChange}
                                  required={editFeed ? false : true}
                                // className='form-control form-control-lg'
                                />
                                <img src={imgPreview} width={150} height={150} />
                              </div>
                            </div>
                            {editFeed && (
                              <div className='col-lg-8 col-sm-11'>
                                <div className='form-group'>
                                  <label htmlFor='subject'>Status</label>
                                  <select value={status} onChange={(e) => setStatus(e.target.value)} className="custom-select" id="inputGroupSelect01">
                                    <option>Choose...</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                  </select>
                                </div>
                              </div>
                            )}
                            <div className='col-lg-8 col-sm-11' style={{ height: '300px' }}>
                              <div className='form-group'>
                                <label htmlFor='subject'>Description</label>
                                <ReactQuill style={{ height: '200px' }} theme="snow" modules={modules} value={description} onChange={setDescription} placeholder="Content goes here..." required />
                              </div>
                            </div>

                            <div className='col-lg-8 col-sm-11'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                              <div className='form-group'>
                                <button
                                  type='submit'
                                  className="btn btn-primary">{editFeed ? 'Edit Post' : 'Submit'}</button>
                              </div>
                              <div className='form-group'>
                                <button
                                  onClick={() => setShowForm(false)}
                                  className="btn btn-danger">Cancel</button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <>
                            <h5 className="title mt-4 mb-4 ">Create Post</h5>
                            <p className='text-danger mt-2 mb-4'>No feed types found, Kindly add them if there isnt any to activate this form</p>
                            <Link to='/mobilefeed/types' className="btn btn-primary">
                              Go To Feed Types
                            </Link>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="card-header">
                          <button onClick={() => {
                                setFeedTypeId('')
                                setEditFeedId('')
                                setImgPreview('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y')
                                setTitle('')
                                setSubTitle('')
                                setDescription('')
                                setStatus('')
                                setEditFeed(false)
                            setShowForm(true)
                          }} className="btn btn-primary btn-icon btn-round btn-lg">
                            <i className="fas fa-plus"></i>
                          </button>
                          <h5 className="title mt-3">Feed Added By You</h5>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="text-primary">
                                <th>#</th>
                                <th>Title</th>
                                <th>Status</th>
                                <th>Created on</th>
                                <th colSpan={2}></th>
                              </thead>
                              <tbody>
                                {feedData.length >= 1 ? (
                                  <>
                                    {feedData.map((item, index) => {
                                      const sdate = item.created_on.split('T')
                                      item.created_on = new Date(sdate[0]).toLocaleString('en-us', { month: 'short', year: 'numeric', day: '2-digit' });
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <img src={item.image} width={100} height={100} />
                                          </td>
                                          <td>
                                            {item.title}
                                          </td>
                                          <td>
                                            {item.status === '1' ? 'Active' : 'In-active'}
                                          </td>
                                          <td className="text-right">
                                            {item.created_on}
                                          </td>
                                          <td className="text-right">
                                            <button data-toggle="modal" data-target="#exampleModal" onClick={() => {
                                              setFeedTypeId(item.feed_type_id)
                                              setEditFeedId(item.id)
                                              setImgPreview(item.image)
                                              setTitle(item.title)
                                              setSubTitle(item.subtitle)
                                              setDescription(item.description)
                                              setStatus(item.status)
                                              setEditFeed(true)
                                              setShowForm(true)
                                            }} className="btn btn-primary btn-circle">
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          </td>
                                          <td className="text-right">
                                            <button onClick={async () => {
                                              setLoading(true)
                                              await authorizeDeleteRequest(`mobileApp/feed/${item.id}`)
                                                .then((response1) => {
                                                  authorizeGetRequest('mobileApp/feed/admin/0')
                                                    .then((response) => {
                                                      if (response.success === true) {
                                                        if (response.feeds !== 0) {
                                                          setFeedData(response.feeds)
                                                        } else {
                                                          setFeedData([])
                                                        }
                                                      } else {
                                                        setFeedData([])
                                                        // console.log('adminFeed failed', response);
                                                      }
                                                    })
                                                    .catch((err) => {
                                                      setFeedData([])
                                                      // console.log('adminFeed error', err);
                                                    });
                                                  Swal.fire({
                                                    position: 'top-end',
                                                    toast: true,
                                                    width: 600,
                                                    // background: '#F47E52',
                                                    icon: 'success',
                                                    titleText: response1.message,
                                                    showConfirmButton: false,
                                                    timer: 2500,
                                                  });
                                                })
                                                .catch((err) => {
                                                  // console.log('mobileAppFeed error', err);
                                                  Swal.fire({
                                                    position: 'top-end',
                                                    toast: true,
                                                    width: 600,
                                                    // background: '#F47E52',
                                                    icon: 'error',
                                                    titleText: err.message,
                                                    showConfirmButton: false,
                                                    timer: 2500,
                                                  });
                                                });
                                              setLoading(false);
                                            }} className="btn btn-danger btn-circle">
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td className='text-center' colSpan={5}>
                                      The feed you post will appear here
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex">
                            {pagBack && (
                              <button onClick={() => {
                                if (feedData.length > 15) {
                                  const dt = feedData[feedData.length - 1]
                                  console.log('element', dt)
                                } else {
                                  setPagBack(false);
                                }
                              }} className="btn btn-primary btn-icon btn-round mr-2">
                                <i className="fas fa-chevron-left"></i>
                              </button>
                            )}
                            {pagForward && (
                              <button onClick={() => {
                                if (feedData.length > 15) {

                                } else {
                                  setPagForward(false);
                                }
                              }} className="btn btn-primary btn-icon btn-round">
                                <i className="fas fa-chevron-right"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {id === 'types' && (
                  <>
                      {showForm ? (
                        <div className='ml-5'>
                          <form method='post' className='p-3' onSubmit={onSubmitFeedType}>
                              <h5 className="title">Add Feed Type</h5>

                              <div className='col-lg-8 col-sm-11'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                                <div className='form-group'>
                                  <label htmlFor='subject'>Type</label>
                                  <input
                                  name='feedType'
                                  value={feedType}
                                  onChange={(e) => setFeedType(e.target.value)}
                                    required
                                    className='form-control form-control-lg'
                                  />
                                </div>
                              </div>
                              <div className='col-lg-8 col-sm-11'>
                                <div className='form-group'>
                                  <label htmlFor='subject'>Description</label>
                                  <input
                                  name='feedDescription'
                                  value={feedDescription}
                                  onChange={(e) => setFeedDescription(e.target.value)}
                                    required
                                    className='form-control form-control-lg'
                                  />
                                </div>
                              </div>
                              {editFeed && (
                                <>
                                <div className='col-lg-8 col-sm-11'>
                                  <div className='form-group'>
                                    <label htmlFor='subject'>Status</label>
                                    <select value={status} onChange={(e) => setStatus(e.target.value)} className="custom-select" id="inputGroupSelect01">
                                      <option>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-lg-8 col-sm-11'>
                                  <div className='form-group'>
                                    <label htmlFor='subject'>Updates Avaliable</label>
                                    <select value={feedTypeUpdates} onChange={(e) => setfeedTypeUpdates(e.target.value)} className="custom-select" id="inputGroupSelect01">
                                      <option>Choose...</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>
                              </>
                              )}

                              <div className='col-lg-8 col-sm-11'>
                                {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                                <div className='form-group'>
                                  <button
                                    type='submit'
                                    className="btn btn-primary">{editFeed ? 'Edit Post' : 'Submit'}</button>
                                </div>
                                <div className='form-group'>
                                  <button
                                    onClick={() => setShowForm(false)}
                                    className="btn btn-danger">Cancel</button>
                                </div>
                              </div>
                            </form>
                        </div>
                      ) : (
                        <>
                          <div className="card-header">
                            <button onClick={() => {
                              setShowForm(true)
                              setEditFeed(false)
                              setFeedDescription('')
                              setFeedType('')
                            }} className="btn btn-primary btn-icon btn-round btn-lg">
                              <i className="fas fa-plus"></i>
                            </button>
                            <h5 className="title mt-3">Feed Types</h5>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead className="text-primary">
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Updates Avaliable</th>
                                    <th colSpan={2}></th>
                                </thead>
                                <tbody>
                                    {feedTypedata.length >= 1 ? (
                                    <>
                                      {feedTypedata.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <th>{item.type}</th>
                                            <th>{item.description}</th>
                                            <th>{item.status === '1' ? 'Active' : 'In-active'}</th>
                                            <th>{item.updates_avaliable}</th>
                                            <td className="text-right">
                                              <button onClick={() => {
                                                setEditFeedId(item.id)
                                                setFeedDescription(item.description)
                                                setFeedType(item.type)
                                                setStatus(item.status)
                                                setfeedTypeUpdates(item.updates_avaliable)
                                                setEditFeed(true)
                                                setShowForm(true)
                                              }} className="btn btn-primary btn-circle">
                                                <i className="fas fa-edit"></i>
                                              </button>
                                            </td>
                                            <td className="text-right">
                                              <button onClick={async () => {
                                                setLoading(true)
                                                await authorizeDeleteRequest(`mobileApp/feedType/${item.id}`)
                                                  .then((response1) => {
                                                    authorizeGetRequest('mobileApp/feed/types/0')
                                                      .then((response) => {
                                                        // console.log('mobileAppFeedTypes success', response);
                                                        if (response.feedTypes !== 0) {
                                                          setFeedTypeData(response.feedTypes)
                                                        } else {
                                                          setFeedTypeData([])
                                                        }
                                                      })
                                                      .catch((err) => {
                                                        setFeedTypeData([])
                                                        // console.log('mobileAppFeedType error', err);
                                                      });
                                                    Swal.fire({
                                                      position: 'top-end',
                                                      toast: true,
                                                      width: 600,
                                                      // background: '#F47E52',
                                                      icon: 'success',
                                                      titleText: response1.message,
                                                      showConfirmButton: false,
                                                      timer: 2500,
                                                    });
                                                  })
                                                  .catch((err) => {
                                                    // console.log('mobileAppFeed error', err);
                                                    Swal.fire({
                                                      position: 'top-end',
                                                      toast: true,
                                                      width: 600,
                                                      // background: '#F47E52',
                                                      icon: 'error',
                                                      titleText: err.message,
                                                      showConfirmButton: false,
                                                      timer: 2500,
                                                    });
                                                  });
                                                setLoading(false);
                                              }} className="btn btn-danger btn-circle">
                                                <i className="fas fa-trash"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      }
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td className='text-center' colSpan={6}>
                                        Feed types  will appear here
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex">
                              {pagBack && (
                                <button onClick={() => {
                                  if (feedData.length > 15) {
                                    const dt = feedData[feedData.length - 1]
                                    console.log('element', dt)
                                  } else {
                                    setPagBack(false);
                                  }
                                }} className="btn btn-primary btn-icon btn-round mr-2">
                                  <i className="fas fa-chevron-left"></i>
                                </button>
                              )}
                              {pagForward && (
                                <button onClick={() => {
                                  if (feedData.length > 15) {

                                  } else {
                                    setPagForward(false);
                                  }
                                }} className="btn btn-primary btn-icon btn-round">
                                  <i className="fas fa-chevron-right"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileAppFeed

import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Dashboard from './Dashboard'
import WebsiteContent from './WebsiteContent'
import MobileAppFeed from './MobileAppFeed'
import MobileAppCases from './MobileAppCases'
import MobileAppCommunities from './MobileAppCommunities'
import AdminAccounts from './AdminAccounts'
import Profile from './Profile'

const Home = ({page}) => {
  let navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      if (!localStorage.RHFAdmintoken){
        return navigate("/signin");
      }
    }
    getData()
  }, [])
  
  return (
    <div className="wrapper">
      <Sidebar page={page} />

      <div className="main-panel" id="main-panel">
        <Navbar page={page} />
        <div className="panel-header panel-header-sm">
        </div>
        {page === 'dashboard' && (
          <Dashboard />
        )}
        {page === 'website' && (
          <WebsiteContent />
        )}
        {page === 'mobilefeed' && (
          <MobileAppFeed />
        )}
        {page === 'mobilecases' && (
          <MobileAppCases />
        )}
        {page === 'mobilecommunities' && (
          <MobileAppCommunities />
        )}
        {page === 'accounts' && (
          <AdminAccounts />
        )}
        {page === 'profile' && (
          <Profile />
        )}
        <Footer />
      </div>
    </div>
  )
}

export default Home

import React, { useState, useEffect } from 'react'
import authorizePostMultipart from '../api/authorizePostMultipart';
import ImgNContent from './ImgNContent';
import authorizeGetRequest from '../api/authorizeGetRequest';

const HomeModal = ({ screen, data, setHomeData, handleBannerDataAdd, handleBannerImgChange, handleBannerDataDelete, onChangeCaption, onChangeBannerTitle }) => {
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [formTitle, setFormTitle] = useState('')
  const [description, setDescription] = useState('')
  const [founderCaption, setFounderCaption] = useState('')
  const [wordCount1, setWordCount1] = useState(50)
  const [wordCount2, setWordCount2] = useState(125)
  const [wordCount3, setWordCount3] = useState(200)
  const [imgPreview, setImgPreview] = useState('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
  const [img, setImg] = useState('')
  const [programsFeed, setProgramsFeed] = useState([])
  const [pg1, setpg1] = useState('')
  const [pg2, setpg2] = useState('')
  const [pg3, setpg3] = useState('')

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      setSuccessMessage('')
      setErrorMessage('')
      if (screen === 'banners') {
        setTitle('Change Banner Images and content')
      } else if (screen === 'aboutus') {
        // console.log('bannerContent', data)
        setTitle('Change About us section content')
        setFormTitle(data.title)
        setFounderCaption(data.founderCaption)
        setImgPreview(data.img)
        setDescription(data.description)
      } else if (screen === 'programareas') {
        // console.log('dataaa', data)
        setpg1(data.pga1.id)
        setpg2(data.pga2.id)
        setpg3(data.pga3.id)
        setTitle('Change Program areas section content')
        await authorizeGetRequest('program/0')
          .then((response) => {
            // console.log('response.programAreas', response.programAreas)
            if (response.programAreas !== 0) {
              setProgramsFeed(response.programAreas)
            } else {
              setProgramsFeed([])
            }
          })
          .catch((err) => {
            setProgramsFeed([])
            console.log('programAreas error', err);
          });
      } else if (screen === 'campaigns') {
        setTitle('Change Campaigns section content')
        setpg1(data.pga1.id)
        setpg2(data.pga2.id)
        setpg3(data.pga3.id)
        await authorizeGetRequest('campaign/0')
          .then((response) => {
            // console.log('campaigns', response.campaigns);
            if (response.campaigns !== 0) {
              setProgramsFeed(response.campaigns)
            } else {
              setProgramsFeed([])
            }
          })
          .catch((err) => {
            setProgramsFeed([])
            console.log('campaigns error', err);
          });
      }
      setLoading(false)
    }
    getData()
  }, [screen, data])

  const handleImgChange = e => {
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg(e.target.files[0]);
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    // console.log('bannerContent', bannerContent)
    const formData = new FormData();
    if (screen === 'banners') {
      data.forEach((item, index) => {
        const indx = index + 1
        formData.append(`img${indx}`, item.img);
        item.img = ''
      });
      // console.log('bannerContent', bannerContent)
      formData.append('pageId', 1);
      formData.append('contentPosition', 1);
      formData.append('content', JSON.stringify(data));
    }
    if (screen === 'aboutus') {
      const dt = {
        img: data.img,
        title: formTitle,
        founderCaption,
        description,
      }
      if(img){
        formData.append('img1', img);
      }
      formData.append('pageId', 1);
      formData.append('contentPosition', 2);
      formData.append('content', JSON.stringify(dt));
     }
    if (screen === 'programareas') {
      const dt = {
        pga1: pg1,
        pga2: pg2,
        pga3: pg3,
      }
      // console.log('bannerContent', bannerContent)
      formData.append('pageId', 1);
      formData.append('contentPosition', 3);
      formData.append('content', JSON.stringify(dt));
    }
    if (screen === 'campaigns') {
      const dt = {
        pga1: pg1,
        pga2: pg2,
        pga3: pg3,
      }
      // console.log('bannerContent', bannerContent)
      formData.append('pageId', 1);
      formData.append('contentPosition', 4);
      formData.append('content', JSON.stringify(dt));
    }
    await authorizePostMultipart('pageContent', formData)
      .then((response) => {
        // console.log('got back pageContent', response.pageContent)
        // setHomeData(response.pageContent)
        setSuccessMessage(response.message)
        window.location.reload();
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        setErrorMessage(err.message)
      });
    setLoading(false)
  }

  return (
    <div className="modal fade" id="homeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {loading ? (
            <>
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <form method='post' onSubmit={handleSubmit}>
              <div className="modal-body">
                {successMessage && (
                  <div className="alert alert-success text-primary">
                    <strong>{successMessage}</strong>
                  </div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger text-primary">
                    <strong>{errorMessage}</strong>
                  </div>
                )}
                {screen === 'banners' && (
                  <>
                    <ImgNContent data={data} handleBannerDataAdd={handleBannerDataAdd} handleBannerImgChange={handleBannerImgChange} handleBannerDataDelete={handleBannerDataDelete} onChangeCaption={onChangeCaption} onChangeBannerTitle={onChangeBannerTitle} />
                  </>
                )}
                {screen === 'aboutus' && (
                  <>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Title</label>
                          <input
                            name='title'
                            value={formTitle}
                            onChange={(e) => {
                              const wcc = 50 - e.target.value.length
                              if (wcc >= 0) {
                                setWordCount1(50 - e.target.value.length)
                                setFormTitle(e.target.value)
                              }
                            }}
                            required
                            className='form-control form-control-lg'
                          />
                          <div className="form-text"><span className="text-primary">{wordCount1}</span> characters remaining</div>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Founder's Caption</label>
                          <input
                            name='founderCaption'
                            value={founderCaption}
                            onChange={(e) => {
                              const wcc = 125 - e.target.value.length
                              if (wcc >= 0) {
                                setWordCount2(125 - e.target.value.length)
                                setFounderCaption(e.target.value)
                              }
                            }}
                            required
                            className='form-control form-control-lg'
                          />
                          <div className="form-text"><span className="text-primary">{wordCount2}</span> characters remaining</div>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Description</label>
                          <textarea
                            name='description'
                            value={description}
                            onChange={(e) => {
                              const wcc = 200 - e.target.value.length
                              if (wcc >= 0) {
                                setWordCount3(200 - e.target.value.length)
                                setDescription(e.target.value)
                              }
                            }}
                            className='form-control form-control-lg'></textarea>
                          <div className="form-text"><span className="text-primary">{wordCount3}</span> characters remaining</div>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Image</label>
                          <div className='col-sm-12 p-0 mb-2'>
                          <input
                            type="file"
                            name='img'
                            accept="image/*"
                            multiple={false}
                            onChange={handleImgChange} />
                            <img className="mt-2" src={imgPreview} height={150} />
                          </div>
                        </div>
                      </div>
                  </>
                )}
                  {screen === 'programareas' && (
                    <>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Program Area 1</label>
                          <select value={pg1} onChange={(e) => setpg1(e.target.value)} className="custom-select" id="inputGroupSelect01">
                            <option>Choose...</option>
                            {programsFeed.map((item, index) => (
                              <option key={index} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Program Area 2</label>
                          <select value={pg2} onChange={(e) => setpg2(e.target.value)} className="custom-select" id="inputGroupSelect01">
                            <option>Choose...</option>
                            {programsFeed.map((item, index) => (
                              <option key={index} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Program Area 3</label>
                          <select value={pg3} onChange={(e) => setpg3(e.target.value)} className="custom-select" id="inputGroupSelect01">
                            <option>Choose...</option>
                            {programsFeed.map((item, index) => (
                              <option key={index} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {screen === 'campaigns' && (
                    <>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Campaign 1</label>
                          <select value={pg1} onChange={(e) => setpg1(e.target.value)} className="custom-select" id="inputGroupSelect01">
                            <option>Choose...</option>
                            {programsFeed.map((item, index) => (
                              <option key={index} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Campaign 2</label>
                          <select value={pg2} onChange={(e) => setpg2(e.target.value)} className="custom-select" id="inputGroupSelect01">
                            <option>Choose...</option>
                            {programsFeed.map((item, index) => (
                              <option key={index} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='subject'>Campaign 3</label>
                          <select value={pg3} onChange={(e) => setpg3(e.target.value)} className="custom-select" id="inputGroupSelect01">
                            <option>Choose...</option>
                            {programsFeed.map((item, index) => (
                              <option key={index} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary">Yes, proceed</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeModal

import React, { useState, useEffect } from 'react'
import authorizePostMultipart from '../api/authorizePostMultipart';
import TitleNParagraph from './TitleNParagraph';

const AboutModal = ({ screen, data, setAboutData }) => {
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [img, setImg] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [imgPreview, setImgPreview] = useState('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
  const [formTitle, setFormTitle] = useState('')
  const [programContent, setProgramContent] = useState([
    { id: 0, title: '', titleVisible: true, paragraph: '' }
  ])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      setSuccessMessage('')
      setErrorMessage('')
      if (screen === 'headerimg'){
        setTitle('Change Header Image')
        setImgPreview(data)
      } else if (screen === 'aboutcontent') {
        setTitle('Change Page Content')
        setProgramContent(data.data)
        setFormTitle(data.title)
      } else if (screen === 'imgs') {

      } else if (screen === '') {

      }
      setLoading(false)
    }
    getData()
  }, [screen])

  const handleImgChange = e => {
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg(e.target.files[0]);
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    setSuccessMessage('')
    setErrorMessage('')
    const formData = new FormData();
    if (screen === 'headerimg') {
      if (img) {
        formData.append('img1', img);
      }
      formData.append('pageId', 2);
      formData.append('contentPosition', 1);
    } else if (screen === 'aboutcontent') {
      formData.append('pageId', 2);
      formData.append('contentPosition', 2);
      const dt = {
        title: formTitle,
        data: programContent
      }
      formData.append('content', JSON.stringify(dt));
      // console.log('data', dt)
    }
    await authorizePostMultipart('pageContent', formData)
      .then((response) => {
        // console.log('got back', response.pageContent)
        setAboutData(response.pageContent)
        setSuccessMessage(response.message)
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        setErrorMessage(err.message)
      });
    setLoading(false)
  }
  const onChangeParagraphTitle = async (id, item) => {
    let cont = programContent
    cont[id].title = item
    await setProgramContent(cont)
  }
  const handleProgParagraphAdd = async (id) => {
    await setProgramContent([...programContent, { id: id, title: '', titleVisible: true, paragraph: '' }])
    // console.log('add id', id, content)
  }
  const handleParagraphTitleDel = async (id) => {
    let cont = programContent
    cont[id].title = ''
    cont[id].titleVisible = false
    await setProgramContent(cont)
  }
  const handleProgParagraphDelete = async (id) => {
    let cont = programContent
    const indexOfObject = cont.findIndex(object => {
      return object.id === id;
    });
    await cont.splice(indexOfObject, 1);
    setProgramContent(cont)
  }
  const onChangeProgParagraph = async (id, item) => {
    let cont = programContent
    cont[id].paragraph = item
    await setProgramContent(cont)
  }

  return (
    <div className="modal fade" id="aboutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {loading ? (
            <>
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
          <form method='post' onSubmit={onSubmit}>
            <div className="modal-body">
              {successMessage && (
                <div className="alert alert-success text-primary">
                  <strong>{successMessage}</strong>
                </div>
              )}
              {errorMessage && (
                <div className="alert alert-danger text-primary">
                  <strong>{errorMessage}</strong>
                </div>
              )}
              {screen === 'headerimg' && (
                <>
                  <div className='col-lg-8 col-sm-11'>
                    <div className='form-group mt-4 mb-4'>
                      <input
                        type="file"
                        name='img'
                        accept="image/*"
                        multiple={false}
                        onChange={handleImgChange}
                        // required={editFeed ? false : true}
                      // className='form-control form-control-lg'
                      />
                      <img src={imgPreview} alt="img" className='mt-2' width={250} height={150} />
                    </div>
                  </div>
                </>
              )}
                  {screen === 'aboutcontent' && (
                    <>
                      <div className='col-lg-12 col-sm-11'>
                        <div className='form-group mt-4 mb-4'>
                          <label htmlFor='subject'>Title</label>
                          <input
                            name='formTitle'
                            value={formTitle}
                            onChange={(e) => setFormTitle(e.target.value)}
                            required
                            className='form-control form-control-lg'
                          />
                        </div>
                      </div>
                      <TitleNParagraph paragraphs={programContent} onChangeParagraphTitle={onChangeParagraphTitle} handleParagraphTitleDel={handleParagraphTitleDel} handleParagraphAdd={handleProgParagraphAdd} handleParagraphDelete={handleProgParagraphDelete} onChangeParagraph={onChangeProgParagraph} />
                    </>
                  )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="submit"  className="btn btn-primary">Yes, proceed</button>
            </div>
          </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default AboutModal

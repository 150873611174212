import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import authorizeGetRequest from '../api/authorizeGetRequest';
import authorizeDeleteRequest from '../api/authorizeDeleteRequest';
import authorizePostMultipart from '../api/authorizePostMultipart';
import authorizePatchMultipart from '../api/authorizePatchMultipart';
import authorizePostRequest from '../api/authorizePostRequest';
import authorizePatchRequest from "../api/authorizePatchRequest";

const AdminAccounts = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false)
  const [img, setImg] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [imgPreview, setImgPreview] = useState('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
  const [pagBack, setPagBack] = useState(false)
  const [pagForward, setPagForward] = useState(false)
  const [deactivateId, setDeactivateId] = useState('')
  const [deactivateName, setDeactivateName] = useState('')
  const [modalUsage, setModalUsage] = useState('')
  const [userLevel, setUserLevel] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (id === 'approved'){
        await authorizeGetRequest('user/activated')
          .then((response) => {
            // console.log('user success', response);
            if (response.activatedUsers !== 0) {
              setData(response.activatedUsers)
            } else {
              setData([])
            }

          })
          .catch((err) => {
            setData([])
            // console.log('user error', err);
          });
      } else if (id === 'pending') {
        await authorizeGetRequest('user/inactive')
          .then((response) => {
            // console.log('user success', response);
            if (response.inactiveUsers !== 0) {
              setData(response.inactiveUsers)
            } else {
              setData([])
            }

          })
          .catch((err) => {
            setData([])
            // console.log('user error', err.message);
          });
      }
      setLoading(false);
    }
    getData();
  }, [id]);

  const handleImgChange = e => {
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg(e.target.files[0]);
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
  }

  const onSubmitAccount = async (e) => {
    e.preventDefault()
    setLoading(true);
    setErrorMessage('')
    const formData = new FormData();
    if (img) {
      formData.append('avatar', img);
    }
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('userlevel', userLevel);
    // console.log('feed data', formData);
    await authorizePostMultipart('user/register', formData)
      .then((response1) => {
        if (id === 'approved') {
        authorizeGetRequest('user/activated')
          .then((response) => {
            // console.log('user success', response);
            if (response.activatedUsers !== 0) {
              setData(response.activatedUsers)
            } else {
              setData([])
            }

          })
          .catch((err) => {
            setData([])
            // console.log('user error', err);
          });
        } else if (id === 'pending') {
          authorizeGetRequest('user/inactive')
            .then((response) => {
              // console.log('user success', response);
              if (response.inactiveUsers !== 0) {
                setData(response.inactiveUsers)
              } else {
                setData([])
              }
            })
            .catch((err) => {
              setData([])
              // console.log('user error', err);
            });
        }
        setShowForm(false);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response1.message,
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setErrorMessage(err.message)
      });
    setLoading(false);
  }
  const handleStatusSubmit = async () => {
    setLoading(true)
    let option;
    if (modalUsage === 'activate') {
      option = '1'
    } else {
      option = '0'
    }
    await authorizePatchRequest(`user/userprofile/${deactivateId}`, {
      activated: option,
    }).then((response) => {
      if (modalUsage === 'activate') {
        authorizeGetRequest('user/inactive')
          .then((response) => {
            // console.log('user success', response);
            if (response.inactiveUsers !== 0) {
              setData(response.inactiveUsers)
            } else {
              setData([])
            }

          })
          .catch((err) => {
            setData([])
            // console.log('user error', err.message);
          });
      } else {
        authorizeGetRequest('user/activated')
          .then((response) => {
            // console.log('user success', response);
            if (response.activatedUsers !== 0) {
              setData(response.activatedUsers)
            } else {
              setData([])
            }
          })
          .catch((err) => {
            setData([])
            // console.log('user error', err);
          });
      }
      Swal.fire({
        position: 'top-end',
        toast: true,
        width: 600,
        // background: '#F47E52',
        icon: 'success',
        titleText: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    })
      .catch((err) => {
        // console.log('err', err)
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false)
  }

  const deleteUser = async (uid) => {
    setLoading(true)
    // console.log('delete', id)
    await authorizeDeleteRequest(`user/${uid}`)
      .then((response1) => {
        if (id === 'approved') {
        authorizeGetRequest('user/activated')
          .then((response) => {
            // console.log('user success', response);
            if (response.activatedUsers !== 0) {
              setData(response.activatedUsers)
            } else {
              setData([])
            }
          })
          .catch((err) => {
            setData([])
            // console.log('user error', err);
          });
        } else if (id === 'pending') {
          authorizeGetRequest('user/inactive')
            .then((response) => {
              // console.log('user success', response);
              if (response.inactiveUsers !== 0) {
                setData(response.inactiveUsers)
              } else {
                setData([])
              }
            })
            .catch((err) => {
              setData([])
              // console.log('user error', err.message);
            });
        }
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'success',
          titleText: response1.message,
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((err) => {
        // console.log('mobileAppFeed error', err);
        Swal.fire({
          position: 'top-end',
          toast: true,
          width: 600,
          // background: '#F47E52',
          icon: 'error',
          titleText: err.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setLoading(false);
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {loading ? (
              <>
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {id === 'approved' && (
                  <>
                    {showForm ? (
                      <div className='ml-5'>
                          <form method='post' className='p-3' onSubmit={onSubmitAccount}>
                            <h5 className="title">Create New User</h5>
                            <div className='col-lg-8 col-sm-11 mt-4'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                              <div className='form-group'>
                                <label htmlFor='subject'>Account Type</label>
                                <select value={userLevel} onChange={(e) => setUserLevel(e.target.value)} className="form-control form-control-lg" id="inputGroupSelect01" required>
                                  <option>Choose...</option>
                                  <option value='1'>Level 1 - Mobile app cases previlages</option>
                                  <option value='2'>Level 2 - All Mobile previlages</option>
                                  <option value='3'>Level 3 - All previlages</option>
                                </select>
                              </div>
                            </div>

                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>First Name</label>
                                <input
                                  name='firstName'
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>Last Name</label>
                                <input
                                  name='lastName'
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>Email</label>
                                <input
                                  name='email'
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group mt-4 mb-4'>
                                <label className='mr-2' htmlFor='subject'>Image</label>
                                <input
                                  type="file"
                                  name='img'
                                  accept="image/*"
                                  multiple={false}
                                  onChange={handleImgChange}
                                />
                                <img src={imgPreview} width={150} height={150} />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                              <div className='form-group'>
                                <button
                                  type='submit'
                                  className="btn btn-primary">Submit</button>
                              </div>
                              <div className='form-group'>
                                <button
                                  onClick={() => setShowForm(false)}
                                  className="btn btn-danger">Cancel</button>
                              </div>
                            </div>
                          </form>
                      </div>
                    ) : (
                      <>
                        <div className="card-header">
                          <button onClick={() => {
                            setImgPreview('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y')
                            setImg('')
                            setShowForm(true)
                          }} className="btn btn-primary btn-icon btn-round btn-lg">
                            <i className="fas fa-plus"></i>
                          </button>
                          <h5 className="title mt-3">Approved Admin Accounts</h5>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="text-primary">
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Access Level</th>
                                <th>Creation On</th>
                              </thead>
                              <tbody>
                                {data.length >= 1 ? (
                                  <>
                                    {data.map((item, index) => {
                                      const sdate = item.created_at.split('T')[0];
                                      item.created_at = new Date(sdate).toLocaleString('en-us', { month: 'short', year: 'numeric', day: '2-digit' });
                                      let adminLevel;
                                      if (Number(item.userlevel) === 1) {
                                        adminLevel = 'Level 1 (Mobile app cases previlages)'
                                      } else if (Number(item.userlevel) === 2) {
                                        adminLevel = 'Level 2 (All mobile app previlages'
                                      } else if (Number(item.userlevel) === 3) {
                                        adminLevel = 'Level 3 (All previlages)'
                                      }
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <img src={item.avatar} width={100} height={100} />
                                          </td>
                                          <td>{`${item.firstName} ${item.lastName}`}</td>
                                          <td>{item.email}</td>
                                          <td>{adminLevel}</td>
                                          <td className="text-right">
                                            {item.created_at}
                                          </td>
                                          <td className="text-right">
                                            <button data-toggle="modal" data-target="#changeModal" onClick={()=>{
                                              setDeactivateName(`${item.firstName} ${item.lastName}`)
                                              setDeactivateId(item.id)
                                              setModalUsage('deactivate')
                                            }} className="btn btn-primary btn-circle">
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          </td>
                                          <td className="text-right">
                                            <button onClick={() => deleteUser(item.id)} className="btn btn-danger btn-circle">
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td className='text-center' colSpan={5}>
                                      Approved admins will appear here
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex">
                            {pagBack && (
                              <button onClick={() => {
                                if (data.length > 15) {
                                  const dt = data[data.length - 1]
                                  console.log('element', dt)
                                } else {
                                  setPagBack(false);
                                }
                              }} className="btn btn-primary btn-icon btn-round mr-2">
                                <i className="fas fa-chevron-left"></i>
                              </button>
                            )}
                            {pagForward && (
                              <button onClick={() => {
                                if (data.length > 15) {

                                } else {
                                  setPagForward(false);
                                }
                              }} className="btn btn-primary btn-icon btn-round">
                                <i className="fas fa-chevron-right"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {id === 'pending' && (
                  <>
                      {showForm ? (
                        <div className='ml-5'>
                          <form method='post' className='p-3' onSubmit={onSubmitAccount}>
                            <h5 className="title">Create New User</h5>
                            <div className='col-lg-8 col-sm-11 mt-4'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                              <div className='form-group'>
                                <label htmlFor='subject'>Account Type</label>
                                <select value={userLevel} onChange={(e) => setUserLevel(e.target.value)} className="form-control form-control-lg" id="inputGroupSelect01" required>
                                  <option>Choose...</option>
                                  <option value='1'>Level 1 - Mobile app cases previlages</option>
                                  <option value='2'>Level 2 - Mobile app previlages</option>
                                  <option value='3'>Level 3 - All previlages</option>
                                </select>
                              </div>
                            </div>

                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>First Name</label>
                                <input
                                  name='firstName'
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>Last Name</label>
                                <input
                                  name='lastName'
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group'>
                                <label htmlFor='subject'>Email</label>
                                <input
                                  name='email'
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                  className='form-control form-control-lg'
                                />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              <div className='form-group mt-4 mb-4'>
                                <label className='mr-2' htmlFor='subject'>Image</label>
                                <input
                                  type="file"
                                  name='img'
                                  accept="image/*"
                                  multiple={false}
                                  onChange={handleImgChange}
                                />
                                <img src={imgPreview} width={150} height={150} />
                              </div>
                            </div>
                            <div className='col-lg-8 col-sm-11'>
                              {errorMessage && <p className='text-danger text-center'>{errorMessage}</p>}
                              <div className='form-group'>
                                <button
                                  type='submit'
                                  className="btn btn-primary">Submit</button>
                              </div>
                              <div className='form-group'>
                                <button
                                  onClick={() => setShowForm(false)}
                                  className="btn btn-danger">Cancel</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <>
                          <div className="card-header">
                            <button onClick={() => {
                              setImgPreview('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y')
                              setImg('')
                              setShowForm(true)
                            }} className="btn btn-primary btn-icon btn-round btn-lg">
                              <i className="fas fa-plus"></i>
                            </button>
                            <h5 className="title mt-3">In-Active Admin Accounts</h5>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead className="text-primary">
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Access Level</th>
                                  <th>Creation On</th>
                                </thead>
                                <tbody>
                                  {data.length >= 1 ? (
                                    <>
                                      {data.map((item, index) => {
                                        const sdate = item.created_at.split('T')[0];
                                        item.created_at = new Date(sdate).toLocaleString('en-us', { month: 'short', year: 'numeric', day: '2-digit' });
                                        let adminLevel;
                                        if (Number(item.userlevel) === 1) {
                                          adminLevel = 'Level 1'
                                        } else if (Number(item.userlevel) === 2) {
                                          adminLevel = 'Level 2'
                                        } else if (Number(item.userlevel) === 3) {
                                          adminLevel = 'Level 3'
                                        }
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <img src={item.avatar} width={100} height={100} />
                                            </td>
                                            <td>{`${item.firstName} ${item.lastName}`}</td>
                                            <td>{item.email}</td>
                                            <td>{adminLevel}</td>
                                            <td className="text-right">
                                              {item.created_at}
                                            </td>
                                            <td className="text-right">
                                              <button data-toggle="modal" data-target="#changeModal" onClick={() => {
                                                setDeactivateName(`${item.firstName} ${item.lastName}`)
                                                setDeactivateId(item.id)
                                                setModalUsage('activate')
                                              }} className="btn btn-primary btn-circle">
                                                <i className="fas fa-edit"></i>
                                              </button>
                                            </td>
                                            <td className="text-right">
                                              <button onClick={() => deleteUser(item.id)} className="btn btn-danger btn-circle">
                                                <i className="fas fa-trash"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      }
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td className='text-center' colSpan={5}>
                                        Inactive accounts will appear here
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex">
                              {pagBack && (
                                <button onClick={() => {
                                  if (data.length > 15) {
                                    const dt = data[data.length - 1]
                                    console.log('element', dt)
                                  } else {
                                    setPagBack(false);
                                  }
                                }} className="btn btn-primary btn-icon btn-round mr-2">
                                  <i className="fas fa-chevron-left"></i>
                                </button>
                              )}
                              {pagForward && (
                                <button onClick={() => {
                                  if (data.length > 15) {

                                  } else {
                                    setPagForward(false);
                                  }
                                }} className="btn btn-primary btn-icon btn-round">
                                  <i className="fas fa-chevron-right"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="modal fade" id="changeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{modalUsage === 'activate' ? 'Activate Admin ?' : 'De-activate Admin ?'}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {modalUsage === 'activate' ? `Are you sure you want to activate ${deactivateName}` : `Are you sure you want to deactivate ${deactivateName}`}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={handleStatusSubmit} data-dismiss="modal" className="btn btn-primary">Yes, proceed</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAccounts
